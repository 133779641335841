import { Block } from '@/components/block';
import { Marquee } from '@/components/ui/marquee';
import { LogoPromoBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const LogoPromoBlock = ({
  blockAlignment,
  body,
  id,
  heading,
  variant = 'light',
  noTopPadding = false,
  logoList,
  isMarquee = false,
  blockSize,
}: LogoPromoBlockProps) => {
  const { base, grid, logo, marquee, leftGradient, rightGradient } = styles({ variant, isMarquee });
  const logoArr = logoList.split(',');

  return (
    <div className={base()}>
      <Block
        id={id}
        heading={heading}
        headingVariant="h1"
        headingElement="h2"
        body={body}
        blockAlignment={blockAlignment}
        noTopPadding={noTopPadding}
        size={blockSize}
      >
        {isMarquee ? (
          <div className={marquee()}>
            <Marquee pauseOnHover>
              {logoArr.map((logoPath) => (
                <div key={logoPath}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img src={logoPath} alt="Brand logo" className={logo()} />
                </div>
              ))}
            </Marquee>
            <div className={leftGradient()} />
            <div className={rightGradient()} />
          </div>
        ) : (
          <ul className={grid()}>
            {logoArr.map((logoPath) => (
              <li key={logoPath}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={logoPath} alt="Brand logo" className={logo()} />
              </li>
            ))}
          </ul>
        )}
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'text-text-primary [&_h2]:mb-4',
    grid: 'mx-auto -mt-6 flex max-w-[--narrow-center-content-max-width] flex-wrap justify-center',
    logo: 'm-4 max-h-10',
    marquee:
      'relative mx-auto flex w-full max-w-3xl flex-col items-center justify-center overflow-hidden max-md:-mx-4 max-md:w-[calc(100%+2rem)] md:-mt-2',
    leftGradient: 'pointer-events-none absolute inset-y-0 left-0 w-6 bg-gradient-to-r',
    rightGradient: 'pointer-events-none absolute inset-y-0 right-0 w-6 bg-gradient-to-l',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white [&_p]:text-grey-600',
        leftGradient: 'from-white',
        rightGradient: 'from-white',
      },
      grey: {
        base: 'bg-grey-50 [&_p]:text-grey-600',
        leftGradient: 'from-grey-100',
        rightGradient: 'from-grey-100',
      },
    },
    isMarquee: {
      true: {
        logo: 'mx-2 my-0 max-h-12',
      },
    },
  },
});

export { LogoPromoBlock };
