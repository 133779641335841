import { useRouter } from 'next/router';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { Button } from '@/components/button';
import { LocationAutocomplete } from '@/components/location-autocomplete';
import { AIRPORT, SEAPORT } from '@/lib/constants';
import { portsSearchParamsState } from '@/state/ports';
import { PlaceInterface } from '@/types/api-types';
import { createPortInsightsDeepLinkUrl } from '@/utils/helpers';
import { createPortInsightsDeepLinkPlaceUrl } from '@/utils/helpers/page-urls';
import { tv } from '@/utils/styles';

const PortsSearchAutocomplete = () => {
  const router = useRouter();
  const [searchParams, setSearchParams] = useRecoilState(portsSearchParamsState);

  const [isLoading, setIsLoading] = useState(false);
  const [canSearch, setCanSearch] = useState(false);

  const handleSelectLocation = (location?: PlaceInterface) => {
    if (!location) return;

    setSearchParams((prev) => ({
      ...prev,
      origin: location,
    }));

    setCanSearch(true);
  };

  const handleSearch = () => {
    const { type, identifier, shortName, name } = searchParams.origin || {};

    setIsLoading(true);

    // Push to Port insights page
    if ((type === AIRPORT || type === SEAPORT) && shortName && identifier) {
      const url = createPortInsightsDeepLinkUrl(shortName, identifier);
      router.push(url);
    } else if (name) {
      const url = createPortInsightsDeepLinkPlaceUrl(name);
      router.push(url);
    }
  };

  const { base, autocomplete, searchButton } = styles();

  return (
    <div className={base()}>
      <LocationAutocomplete
        name="ports"
        label="Find ports near"
        placeholder="Search by port name or place"
        onSelect={handleSelectLocation}
        className={autocomplete()}
      />
      <Button
        iconLeft="search"
        isLoading={isLoading}
        disabled={!canSearch}
        onClick={handleSearch}
        className={searchButton()}
        data-cy="port-insights-search-button"
      >
        Search
      </Button>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex max-md:flex-col',
    autocomplete: 'max-md:!mb-4 md:[&_label+div]:w-96 [&_label]:text-white',
    searchButton:
      'h-[--input-height] self-start pl-base pr-base text-baseSm font-normal tracking-[0.02rem] max-sm:w-full sm:pl-base sm:pr-base md:ml-2 md:self-end',
  },
});

export { PortsSearchAutocomplete };
