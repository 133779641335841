import { Block } from '@/components/block';
import { Image } from '@/components/image';
import { WebBrowserWrapper } from '@/components/web-browser-wrapper';
import { ProductHeaderBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ProductHeaderBlock = ({
  blockAlignment,
  body,
  id,
  heading,
  image,
  variant = 'dark',
  noTopPadding = false,
}: ProductHeaderBlockProps) => {
  const { base, wrapper, bottom } = styles({ variant });
  return (
    <div className={base()}>
      <div className={wrapper()}>
        <Block
          id={id}
          heading={heading}
          headingVariant="d2"
          headingElement="h1"
          body={body}
          blockAlignment={blockAlignment}
          contentColumns={{
            left: 'w-full',
            center: 'w-full',
            right: 'w-full',
          }}
          noTopPadding={noTopPadding}
        >
          <div className="mt-2 md:mt-6">
            <WebBrowserWrapper>
              <Image data={image} />
            </WebBrowserWrapper>
          </div>
        </Block>
      </div>
      <div className={bottom()} />
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'relative',
    wrapper: 'mx-auto max-w-[950px] pb-6 pt-16',
    bottom: '-mt-40 h-40 bg-white md:-mt-80 md:h-80',
  },
  variants: {
    variant: {
      light: {
        base: 'text-text-primary [&_p]:text-text-primary',
      },
      dark: {
        base: 'bg-blue-600 text-white [&_p]:text-white',
      },
    },
  },
});

export { ProductHeaderBlock };
