import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Block } from '@/components/block';
import { PostCard } from '@/components/cards/post-card';
import { Pagination } from '@/components/pagination';
import { POST_TYPES } from '@/lib/constants';
import { PostsBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const PostsBlock = ({
  id,
  heading,
  body,
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  posts,
  postType,
  showPagination = true,
  itemsPerPage = 12,
  ...props
}: PostsBlockProps) => {
  const [pageNumber, setPageNumber] = useState(1);

  if (isEmpty(posts)) return null;

  const firstPostIndex = (pageNumber - 1) * itemsPerPage;
  const lastPostIndex = firstPostIndex + itemsPerPage;

  // Get the posts for the current page
  const currentPosts = posts.slice(firstPostIndex, lastPostIndex);

  const handlePageChange = (value: number) => {
    setPageNumber(value);
  };

  const { base } = styles({ variant: postType });

  return (
    <Block
      id={id}
      className="py-12"
      heading={heading}
      headingVariant={headingVariant}
      preHeading={preHeading}
      preHeadingVariant={preHeadingVariant}
      body={body}
      noTopPadding={noTopPadding}
      {...props}
    >
      <ul className={base()}>
        {currentPosts?.map((post) => {
          return (
            <li key={post.id}>
              <PostCard post={post} variant={postType} />
            </li>
          );
        })}
      </ul>

      {showPagination && (
        <Pagination
          totalCount={posts.length}
          perPage={itemsPerPage}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      )}
    </Block>
  );
};

const styles = tv({
  slots: {
    base: 'grid [&_a]:no-underline',
  },
  variants: {
    variant: {
      [POST_TYPES.BLOG]: {
        base: 'grid-cols-1 gap-lg md:grid-cols-2 lg:grid-cols-3 xl:gap-xl 2xl:gap-xxxl',
      },
      [POST_TYPES.PRESS]: {
        base: 'grid-rows-1 gap-none md:grid-cols-none lg:grid-cols-none [&_li]:-mb-px',
      },
      [POST_TYPES.CASE_STUDIES]: {
        base: 'grid-cols-1 gap-lg md:grid-cols-2 lg:grid-cols-3 xl:gap-xl 2xl:gap-xxxl',
      },
    },
  },
});

export { PostsBlock };
