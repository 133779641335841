import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = debounce(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 200);

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        handleResize.cancel();
        window.removeEventListener('resize', handleResize);
      };
    }

    return () => {};
  }, []);

  return windowSize;
};

export { useWindowSize };
