import useSWR from 'swr';

import { CarriersDataApiInterface } from '@/types/api-types';
import { request } from '@/utils/api/request';
import { getAllCarriersUrl } from '@/utils/helpers/api-urls';

export const useCarriers = ({ hasTracking }: { hasTracking?: boolean }) => {
  const { data, error } = useSWR<CarriersDataApiInterface>(getAllCarriersUrl(hasTracking), request, {
    revalidateOnFocus: false,
  });

  return {
    carriers: data?.carriers,
    isLoading: !error && !data,
    error,
  };
};
