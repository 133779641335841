import FocusTrap from 'focus-trap-react';
import { motion } from 'framer-motion';
import { useRecoilState } from 'recoil';

import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { AdvancedSearchForm } from '@/components/modules/advanced-search/form';
import { VisuallyHidden } from '@/components/visually-hidden';
import { advancedSearchFormOpenState } from '@/state/search';
import { tv } from '@/utils/styles';

export interface AdvancedSearchModalProps {
  onSearch: () => void;
}

const AdvancedSearchModal = ({ onSearch }: AdvancedSearchModalProps) => {
  const [isOpen, setIsOpen] = useRecoilState(advancedSearchFormOpenState);

  // This event is called when ESC is pressed (which also deactivates the Trap)
  const handleOnDeactivate = () => {
    setIsOpen(false);
  };

  const { modal, modalContent, overlay, headingWrapper } = styles();

  return (
    <FocusTrap
      focusTrapOptions={{
        onPostDeactivate: handleOnDeactivate,
        allowOutsideClick: true,
      }}
    >
      <div className={modal()}>
        <motion.div
          className={modalContent()}
          initial="hidden"
          exit="hidden"
          animate={isOpen ? 'open' : 'hidden'}
          variants={{
            open: { y: 0 },
            hidden: { y: 30 },
          }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          data-cy="modal"
        >
          <div className={headingWrapper()}>
            <Heading variant="h4" className="m-0">
              Advanced search options
            </Heading>
            <button type="button" onClick={handleOnDeactivate}>
              <Icon name="close" size="md" color="text.primary" />
              <VisuallyHidden>Close</VisuallyHidden>
            </button>
          </div>
          <AdvancedSearchForm onSearch={onSearch} />
        </motion.div>
        <motion.div
          className={overlay()}
          onClick={() => setIsOpen(false)}
          initial="hidden"
          exit="hidden"
          animate={isOpen ? 'open' : 'hidden'}
          variants={{
            open: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          transition={{ duration: 0.2 }}
        />
      </div>
    </FocusTrap>
  );
};

const styles = tv({
  slots: {
    modal: 'fixed bottom-0 left-0 right-0 top-0 z-7 grid place-items-center p-4',
    modalContent:
      'relative z-3 w-[calc(100%-theme(spacing.1))] rounded-lg bg-grey-100 xl:w-[var(--center-content-max-width)]',
    overlay: 'absolute bottom-0 left-0 right-0 top-0 z-2 bg-[rgba(0,0,0,0.2)]',
    headingWrapper: 'flex items-center justify-between border-b border-solid border-grey-200 px-6 py-4',
  },
});

export { AdvancedSearchModal };
