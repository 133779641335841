/**
 * This card uses container queries to adjust the layout based on the width of the card.
 *
 * If the card is wider than 768px (3xl), the image will be on the left and the content on the right. If we find that this is undesirable we can make this optional.
 */

import { Button } from '@/components/button';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { Text } from '@/components/text';
import { Pill } from '@/components/ui/pill';
import { BlockImage } from '@/types/block-types';
import { tv } from '@/utils/styles';

export interface CardProps {
  heading: string;
  body: string;
  image: BlockImage;
  pills?: { label: string }[];
  className?: string;
  buttonText?: string;
  href?: string;
  size?: 'lg';
  isImageFramed?: boolean;
  isReversed?: boolean;
}

const Card = ({
  heading,
  body,
  image,
  pills,
  href,
  size,
  buttonText,
  isImageFramed,
  isReversed,
  className,
}: CardProps) => {
  const isLink = !!href;

  const { base, inner, imageWrapper, content, headingStyles, bodyStyles, ul, linkStyles, footer, buttonStyles } =
    styles({
      isLink,
      size,
      isReversed: !!isReversed,
      isImageFramed: !!isImageFramed,
      hasImage: !!image,
    });

  return (
    <article className={base({ className })}>
      <ConditionalWrapper
        condition={isLink}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(linkChildren) => (
          <Link href={href || ''} className={linkStyles()}>
            {linkChildren}
          </Link>
        )}
      >
        <div className={inner()}>
          <div className={imageWrapper()}>
            {image && <Image data={image} layout="fill" objectFit="cover" objectPosition="50%" />}
          </div>

          <div className={content()}>
            <div>
              <Heading variant="h3" className={headingStyles()}>
                {heading}
              </Heading>
              <Text variant="baseLg" className={bodyStyles()}>
                {body}
              </Text>
              {Boolean(pills) && pills && (
                <ul className={ul()}>
                  {pills.map((pill) => {
                    return (
                      <li key={pill.label}>
                        <Pill label={pill.label} />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {Boolean(isLink) && (
              <footer className={footer()}>
                <Button
                  variant="outline"
                  size={size === 'lg' ? 'large' : 'medium'}
                  isRounded
                  as="span"
                  className={buttonStyles()}
                >
                  {buttonText || 'Read more'}
                </Button>
              </footer>
            )}
          </div>
        </div>
      </ConditionalWrapper>
    </article>
  );
};

const styles = tv({
  slots: {
    base: 'group block h-full w-full overflow-hidden rounded-md border border-grey-300 bg-white shadow-soft transition-all duration-200 ease-in-out @container/card',
    inner: 'flex h-full flex-col @3xl/card:flex-row @5xl/card:min-h-80',
    imageWrapper:
      'card-image-wrapper relative mb-0 aspect-[15/11] max-h-[360px] shrink-0 bg-white @3xl/card:grow sm:mb-0 @3xl/card:sm:mr-0 lg:aspect-[16/11] xl:aspect-[16/10]',
    content:
      'card-content flex h-full flex-col justify-between rounded-b-md p-6 @3xl/card:h-auto @3xl/card:max-w-[50%] sm:p-8',
    headingStyles: 'mb-2 font-semibold text-blue-800',
    bodyStyles: 'm-0 !font-normal !text-grey-500',
    ul: 'mt-4 flex flex-wrap gap-2',
    linkStyles: 'block h-full',
    footer: 'mt-6 w-full @3xl/card:mt-12 @3xl/card:self-end',
    buttonStyles: 'group-hover:border-blue-500',
  },
  variants: {
    size: {
      lg: {
        headingStyles: 'inline pr-1.5',
        bodyStyles: 'inline text-md font-semibold leading-8 first-letter:lowercase',
      },
    },
    isLink: {
      true: {
        base: 'hover:border-grey-350 hover:shadow-card',
      },
    },
    isReversed: {
      true: {
        content: 'block @3xl/card:order-first @3xl/card:flex @3xl/card:!pr-8 lg:order-first',
        imageWrapper: 'mt-auto @3xl/card:sm:ml-0',
      },
    },
    isImageFramed: {
      true: {
        base: '!shadow-none',
        imageWrapper: 'm-6 mb-0 sm:m-8 sm:mb-0 @3xl/card:sm:mb-8',
      },
    },
    hasImage: {
      false: {
        imageWrapper: 'bg-grey-300',
      },
    },
  },
  compoundVariants: [
    {
      isReversed: true,
      isImageFramed: true,
      className:
        '@3xl/card:sm:[&_.card-image-wrapper]:mr-8 @3xl/card:sm:[&_.card-image-wrapper]:mt-8 lg:[&_.card-image-wrapper]:mb-8 @xl/card:lg:[&_.card-image-wrapper]:mt-0 @3xl/card:lg:[&_.card-image-wrapper]:mt-8 xl:[&_.card-image-wrapper]:aspect-square',
    },
  ],
});

export { Card };
