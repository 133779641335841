/**
 * Validates an Airway Bill (AWB) number.
 *
 * An AWB number typically follows this format:
 * - It consists of exactly 11 digits.
 * - The first three digits represent the airline prefix.
 * - The next seven digits represent the serial number.
 * - The last digit is a check digit.
 *
 * The check digit is calculated as follows:
 * 1. Divide the seven-digit serial number by 7.
 * 2. The remainder is the check digit.
 */
export const validateAwbNumber = (awbNumber: string | undefined): boolean => {
  if (!awbNumber) return false;

  const regex = /^\d{11}$/;
  if (!regex.test(awbNumber)) return false;

  const serialNumber = awbNumber.slice(3, 10);
  const checkDigit = parseInt(awbNumber[10], 10);

  const calculatedCheckDigit = parseInt(serialNumber, 10) % 7;

  return checkDigit === calculatedCheckDigit;
};
