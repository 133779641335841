import { Block } from '@/components/block';
import { ButtonGroup } from '@/components/button-group';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { ContentMediaBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ContentMediaBlock = ({
  id,
  heading,
  body,
  children,
  image,
  isReversed = false,
  buttons = [],
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  isImageBackground,
  variant,
  size,
  ...props
}: ContentMediaBlockProps) => {
  const { base, grid, imageCol, contentCol, richText, preHeadingStyles, headingStyles, fakeCol } =
    contentMediaBlockStyles({
      isReversed,
      isImageBackground,
      size,
      variant,
    });

  return (
    <Block id={id} className={base()} noTopPadding={noTopPadding} {...props}>
      <div className={grid()}>
        <div className={imageCol()}>
          <Image
            data={image}
            className="rounded-md"
            objectFit={isImageBackground ? 'cover' : undefined}
            layout={isImageBackground ? 'fill' : 'intrinsic'}
          />
        </div>

        <div className={contentCol()}>
          <div>
            {children || (
              <>
                {preHeading && (
                  <Text variant={preHeadingVariant} className={preHeadingStyles()}>
                    {preHeading}
                  </Text>
                )}
                {heading && (
                  <Heading variant={headingVariant} as="h2" className={headingStyles()}>
                    {heading}
                  </Heading>
                )}
                {body && <RichText className={richText()}>{body}</RichText>}

                {buttons.length ? <ButtonGroup buttons={buttons} className="mt-8 justify-start" size={size} /> : null}
              </>
            )}
          </div>
        </div>

        {/* Fake column to make the grid work when the image is a background image */}
        <div className={fakeCol()} />
      </div>
    </Block>
  );
};

const contentMediaBlockStyles = tv({
  slots: {
    base: 'relative pb-12 pt-12 xl:pb-16 xl:pt-16',
    grid: 'grid-class grid-auto-rows-min relative grid justify-center gap-8 lg:grid-cols-2 lg:grid-rows-none lg:justify-normal lg:gap-8',
    imageCol: 'mx-auto w-full md:max-lg:w-10/12',
    contentCol:
      'content-col relative z-2 mx-auto flex w-full justify-center md:items-center md:max-lg:w-10/12 lg:order-first lg:justify-normal lg:pr-8 xl:pr-16',
    richText: '',
    headingStyles: '',
    preHeadingStyles: 'mb-4 text-grey-500',
    fakeCol: 'fake-col hidden',
  },
  variants: {
    variant: {
      light: {},
      grey: {
        base: 'bg-grey-50',
      },
    },
    isImageBackground: {
      true: {
        grid: 'p-8 lg:grid-cols-[minmax(320px,420px)_auto]',
        contentCol: 'rounded-sm bg-white p-8 md:max-lg:w-full',
        imageCol: 'absolute z-1 h-full w-full md:max-lg:w-full',
      },
    },
    size: {
      lg: {
        richText: 'max-w-3xl text-baseLg leading-7 lg:text-md lg:leading-8',
        headingStyles: 'mb-4 text-lg font-semibold leading-tight text-blue-800 md:text-xl lg:text-xl',
      },
    },
    isReversed: {
      true: {
        contentCol: 'lg:order-last lg:pl-8 xl:pl-16 xl:pr-5',
      },
    },
  },
  compoundVariants: [
    {
      isImageBackground: true,
      isReversed: true,
      class: 'xl:[&_.content-col]:px-8 lg:[&_.fake-col]:block lg:[&_.grid-class]:grid-cols-[auto_minmax(320px,420px)]',
    },
  ],
});

export { ContentMediaBlock };
