import { Block } from '@/components/block';
import { Box } from '@/components/box';
import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Text } from '@/components/text';
import { NARROW_CENTER_CONTENT_MAX_WIDTH } from '@/lib/constants';
import { JobsBlockProps } from '@/types/block-types';

const JobsBlock = ({
  body,
  id,
  heading,
  variant: blockVariant = 'light',
  noTopPadding = false,
  items,
}: JobsBlockProps) => {
  const variantClasses = blockVariant === 'grey' ? 'bg-gray-50 text-text-primary' : 'bg-white text-text-primary';

  return (
    <article className={variantClasses} id="careers">
      <Block id={id} heading={heading} headingVariant="h1" headingElement="h2" body={body} noTopPadding={noTopPadding}>
        <Box maxWidth={NARROW_CENTER_CONTENT_MAX_WIDTH}>
          {items?.map((item) => {
            const { id: itemId, title, body: itemBody, link, isOpen } = item;
            const jobIsOpen = link && isOpen;
            return (
              <div className="mb-base flex items-center justify-between rounded-lg bg-grey-50 p-md" key={itemId}>
                <Box>
                  <Heading variant="h4">{title}</Heading>
                  <Text variant="secondary" className="mb-0 mt-sm text-text-secondary">
                    {itemBody}
                  </Text>
                </Box>
                <Button href={link || '/'} target="_blank" disabled={!jobIsOpen}>
                  {jobIsOpen ? 'Apply now' : 'Job closed'}
                </Button>
              </div>
            );
          })}
        </Box>
      </Block>
    </article>
  );
};

export { JobsBlock };
