import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SubscribeForm } from '@/blocks/subscribe-block/subscribe-form';
import { Block } from '@/components/block';
import { mailingListProvider } from '@/lib/api/providers/fluent/mailing-list';
import { SubscribeFormInputs } from '@/types';
import { SubscribeBlockProps } from '@/types/block-types';
import { logEvent } from '@/utils/logger';
import { trackUser } from '@/utils/tracking';

export const subscribeFormValidationSchema = yup
  .object({
    email: yup.string().email('Enter a valid email address').required('Enter your email address'),
  })
  .required();

const SubscribeBlock = ({ id, preHeading, heading, body, successHeading, successBody }: SubscribeBlockProps) => {
  const formMethods = useForm<SubscribeFormInputs>({
    resolver: yupResolver(subscribeFormValidationSchema),
  });

  const onSubmit: SubmitHandler<SubscribeFormInputs> = async (data) => {
    trackUser.event('Subscribe To News');

    try {
      await mailingListProvider.subscribe(data);
    } catch (error: any) {
      logEvent.error('Subscribe To News Failed', { error });
      formMethods.setError('email', {
        type: 'manual',
        message: error.message,
      });
    }
  };

  const { isSubmitSuccessful } = formMethods.formState;

  return (
    <Block
      id={id}
      preHeading={!isSubmitSuccessful ? preHeading : undefined}
      heading={!isSubmitSuccessful ? heading : undefined}
      body={!isSubmitSuccessful ? body : undefined}
      blockAlignment="center"
      className="bg-lightBlue-50 bg-[url('~public/assets/light-dotted-bg.png')] bg-cover"
      size="lg"
    >
      {body && successHeading && successBody && (
        <FormProvider {...formMethods}>
          <SubscribeForm
            successHeading={successHeading}
            successBody={successBody}
            onSubmit={onSubmit}
            className="-mt-3"
          />
        </FormProvider>
      )}
    </Block>
  );
};

export { SubscribeBlock };
