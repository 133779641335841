import { Box } from '@/components/box';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { Text } from '@/components/text';
import { BlockBody, BlockImage } from '@/types/block-types';

export interface TeamCardProps {
  __typename?: string;
  body?: BlockBody;
  email?: string;
  id: string;
  image?: BlockImage;
  jobTitle?: string;
  name?: string;
}

const TeamCard = ({ email, name, image, body, jobTitle, ...props }: TeamCardProps) => {
  return (
    <div className="flex flex-col" {...props}>
      <div className="relative flex h-32 max-w-[theme(spacing.32)] items-end">
        {image ? (
          <Image data={image} className="max-w-full rounded-lg" />
        ) : (
          <div className="h-32 w-32 rounded-lg bg-lightBlue-50" />
        )}
      </div>
      <Box>
        {name && (
          <Heading variant="h5" as="h3" className="mb-0 mt-3">
            {name}
          </Heading>
        )}
        {jobTitle && <Text className="m-0 mt-1 text-baseSm leading-snug !text-text-secondary">{jobTitle}</Text>}
      </Box>
    </div>
  );
};

export { TeamCard };
