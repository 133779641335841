import { ReactNode } from 'react';

import { tv } from '@/utils/styles';

export interface BentoGridProps {
  children: ReactNode;
  className?: string;
}

const BentoGrid = ({ children, className }: BentoGridProps) => {
  const { base } = styles();

  return <div className={base({ className })}>{children}</div>;
};

const styles = tv({
  slots: {
    base: 'auto-rows grid w-full grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-8',
  },
});

export { BentoGrid };
