import { Block } from '@/components/block';
import { Link } from '@/components/link';
import { LinkBankBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const LinkBankBlock = ({
  heading,
  links,
  noTopPadding = false,
  variant = 'light',
  id = 'link-bank',
  blockSize,
  blockAlignment,
}: LinkBankBlockProps) => {
  const { base, list, linkStyles } = styles({ variant, blockAlignment, blockSize });

  return (
    <div className={base()} id="link-bank">
      <Block
        id={id}
        heading={heading}
        headingVariant="h2"
        noTopPadding={noTopPadding}
        blockAlignment={blockAlignment}
        size={blockSize}
      >
        <ul className={list()}>
          {links &&
            links.links.map((link) => (
              <li key={link.url}>
                <Link href={link.url} className={linkStyles()}>
                  {link.value}
                </Link>
              </li>
            ))}
        </ul>
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'text-text-primary',
    list: 'grid grid-cols-2 gap-x-6 gap-y-4 md:grid-cols-3 lg:grid-cols-4',
    linkStyles: 'leading-snug no-underline hover:text-lightBlue-600 hover:underline',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
    },
    blockAlignment: {
      center: {
        list: '[&_li]:text-center',
      },
      left: {},
      right: {},
    },
    blockSize: {
      lg: {
        list: 'text-baseLg',
      },
    },
  },
});

export { LinkBankBlock };
