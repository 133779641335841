import { kebabCase } from 'lodash';

import { Block } from '@/components/block';
import { Card } from '@/components/cards/card';
import { BentoGrid } from '@/components/ui/bento-grid';
import { BentoGridBlockProps } from '@/types/block-types';
import { getDatoLinkHref } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';

const BentoGridBlock = ({
  id,
  noTopPadding = false,
  className,
  heading,
  preHeading,
  body,
  blockAlignment,
  items,
  blockSize = 'lg',
}: BentoGridBlockProps) => {
  const { base } = styles();

  return (
    <div className={base({ className })}>
      <Block
        id={id}
        noTopPadding={noTopPadding}
        className={className}
        heading={heading}
        preHeading={preHeading}
        body={body}
        blockAlignment={blockAlignment}
        size={blockSize}
      >
        <BentoGrid>
          {items.map((item) => {
            const cardHref = item.link
              ? getDatoLinkHref({
                  internalLink: item.link?.internalLink,
                  externalLink: item.link?.externalLink,
                })
              : undefined;

            return (
              <Card
                heading={item.heading}
                body={item.body}
                image={item.image}
                size={item.size}
                href={cardHref}
                buttonText={item.buttonText}
                className={item.className}
                isReversed={item.isReversed}
                key={kebabCase(item.heading)}
                isImageFramed
              />
            );
          })}
        </BentoGrid>
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'bg-grey-50',
  },
});

export { BentoGridBlock };
