import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cn } from '@/utils/styles';

const DrawerAlt = ({ shouldScaleBackground = true, ...props }: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);
DrawerAlt.displayName = 'DrawerAlt';

const DrawerAltTrigger = DrawerPrimitive.Trigger;

const DrawerAltPortal = DrawerPrimitive.Portal;

const DrawerAltClose = DrawerPrimitive.Close;

const DrawerAltOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn('fixed inset-0 bottom-0 left-0 right-0 top-0 z-10 bg-black/60', className)}
    {...props}
  />
));
DrawerAltOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerAltContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  return (
    <DrawerAltPortal>
      <DrawerAltOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          'fixed inset-x-0 bottom-0 z-11 mt-24 flex h-auto flex-col rounded-t-md shadow-[0_0_40px_-4px_rgba(0,0,0,0.3)]',
          className,
        )}
        {...props}
      >
        <div className="mx-auto mb-3 mt-3 h-1.5 w-[100px] rounded-full bg-grey-300" />
        {children}
      </DrawerPrimitive.Content>
    </DrawerAltPortal>
  );
});
DrawerAltContent.displayName = 'DrawerContent';

const DrawerAltHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('grid gap-1.5 p-4 text-center sm:text-left', className)} {...props} />
);
DrawerAltHeader.displayName = 'DrawerHeader';

const DrawerAltFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex flex-col gap-2 p-4', className)} {...props} />
);
DrawerAltFooter.displayName = 'DrawerFooter';

const DrawerAltTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn('text-md font-semibold leading-none tracking-tight', className)}
    {...props}
  />
));
DrawerAltTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerAltDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => <DrawerPrimitive.Description ref={ref} className={cn(className)} {...props} />);
DrawerAltDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  DrawerAlt,
  DrawerAltClose,
  DrawerAltContent,
  DrawerAltDescription,
  DrawerAltFooter,
  DrawerAltHeader,
  DrawerAltOverlay,
  DrawerAltPortal,
  DrawerAltTitle,
  DrawerAltTrigger,
};
