import { Image, StructuredText } from 'react-datocms';

import { Block } from '@/components/block';
import { Box } from '@/components/box';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Container } from '@/components/container';
import { Flex } from '@/components/flex';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { BlockImage, KeyedObjInterface, LinkInterface, RichTextBlockProps } from '@/types/block-types';
import { getDatoLinkHref } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';

export const blockPositionOptions: KeyedObjInterface = {
  left: 'flex-start',
  center: 'center',
};

const contentColumns = {
  left: { _: 1, md: 7.8 / 12 },
  center: { _: 1, md: 7.8 / 12 },
};

const RichTextBlock = ({
  body,
  id,
  blockAlignment = 'left',
  variant: blockVariant,
  blockPosition = 'center',
  noTopPadding,
  heading,
  __typename,
  preHeading,
  hasSlimContainer,
}: RichTextBlockProps) => {
  const { base, image } = styles({ variant: blockVariant });
  return (
    <div className={base()}>
      <Container
        className="px-none lg:px-none" // To remove double margins as there is already a <Container /> component inside <Block />
      >
        <Flex justifyContent={blockPositionOptions[blockPosition]} flexWrap="wrap">
          <Box width={contentColumns[blockPosition]}>
            <Block
              blockAlignment={blockAlignment}
              id={id}
              hasButtonsAtTheBottom
              body={null}
              noTopPadding={noTopPadding}
              heading={heading}
              preHeading={preHeading}
              hasSlimContainer={hasSlimContainer}
              __typename={__typename}
            >
              <RichText isDatoStructuredText>
                <StructuredText
                  data={body}
                  renderBlock={({ record }) => {
                    switch (record.__typename) {
                      case 'ImageRecord': {
                        const { internalLink, externalLink } = record.link as LinkInterface[][0];

                        const imageLink = getDatoLinkHref({
                          internalLink,
                          externalLink,
                        });

                        const figCaption = (record.image as { title: string })?.title;

                        return (
                          <ConditionalWrapper
                            condition={!!imageLink}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            wrapper={(children) => <Link href={imageLink!}>{children}</Link>}
                          >
                            {/* 'not-rich-text' class used to disable the Tailwind typography plugin styles on images - https://github.com/tailwindlabs/tailwindcss-typography?tab=readme-ov-file#undoing-typography-styles */}
                            <div className="not-rich-text">
                              <Box as="figure" my={{ _: 'base', lg: 'xl' }}>
                                <Box mx={{ _: 0, lg: -7 }}>
                                  <Image
                                    className={image({ isLink: !!imageLink })}
                                    data={(record.image as BlockImage).responsiveImage}
                                    layout="responsive"
                                  />
                                </Box>
                                {figCaption && (
                                  <figcaption className="mt-3 items-center">
                                    <Icon name="camera" size="md" className="mr-sm" />
                                    <Text variant="secondary" as="span" className="text-grey-500">
                                      {figCaption}
                                    </Text>
                                  </figcaption>
                                )}
                              </Box>
                            </div>
                          </ConditionalWrapper>
                        );
                      }
                      default:
                        return null;
                    }
                  }}
                />
              </RichText>
            </Block>
          </Box>
        </Flex>
      </Container>
    </div>
  );
};

const styles = tv({
  slots: {
    base: '',
    image: 'rounded transition duration-200 ease-in-out',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white text-text-primary [&_p]:text-text-primary',
      },
      grey: {
        base: 'bg-grey-50 text-text-primary [&_p]:text-text-primary',
      },
      dark: {
        base: 'bg-blue-600 text-white [&_a]:text-white [&_h2]:text-white [&_h3]:text-white [&_h4]:text-white [&_p]:text-white',
      },
    },
    isLink: {
      true: {
        image: 'hover:shadow-[0_0_0_4px] hover:shadow-blue-400',
      },
    },
  },
});

export { RichTextBlock };
