import { tv } from '@/utils/styles';

export interface MarqueeProps {
  className?: string;
  reverse?: boolean;
  pauseOnHover?: boolean;
  children?: React.ReactNode;
  vertical?: boolean;
  repeat?: number;
}

const Marquee = ({
  className,
  reverse,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 4,
}: MarqueeProps) => {
  const { base, track } = styles({
    vertical,
    reverse,
    pauseOnHover,
  });

  return (
    <div className={base({ className })}>
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div key={i} className={track()}>
            {children}
          </div>
        ))}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'group flex overflow-hidden p-2 [--duration:40s] [--gap:1rem] [gap:var(--gap)]',
    track: 'flex shrink-0 justify-around [gap:var(--gap)]',
  },
  variants: {
    vertical: {
      true: {
        base: 'flex-col',
        track: 'animate-marquee-vertical flex-col',
      },
      false: {
        base: 'flex-row',
        track: 'animate-marquee flex-row',
      },
    },
    pauseOnHover: {
      true: {
        track: 'group-hover:[animation-play-state:paused]',
      },
    },
    reverse: {
      true: {
        track: '[animation-direction:reverse]',
      },
    },
  },
  defaultVariants: {
    vertical: false,
    pauseOnHover: false,
    reverse: false,
  },
});

export { Marquee };
