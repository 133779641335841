import { ArrowRight } from 'lucide-react';

import { Block } from '@/components/block';
import { Heading } from '@/components/heading';
import { Link } from '@/components/link';
import { Text } from '@/components/text';
import { DynamicIcon } from '@/components/ui/dynamic-icon';
import { KeyFeaturesBlockProps } from '@/types/block-types';
import { getInternalDatoLinkHref } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';

const KeyFeaturesBlock = ({
  body,
  id,
  heading,
  preHeading,
  variant,
  blockAlignment,
  size = 'lg',
  noTopPadding,
  keyFeatures,
  buttons,
}: KeyFeaturesBlockProps) => {
  const { base, card, headingStyles, text, iconWrapper, list, moreButton, moreArrow } = keyFeaturesBlockStyles({
    variant,
    blockAlignment,
  });

  return (
    <div id="key-features" className={base()}>
      <Block
        id={id}
        className="pb-12 pt-14 xl:py-20"
        noTopPadding={noTopPadding}
        heading={heading}
        preHeading={preHeading}
        body={body}
        blockAlignment="center"
        buttons={buttons}
        size={size}
        hasButtonsAtTheBottom
      >
        <ul className={list()}>
          {keyFeatures.map((item) => {
            const { id: itemId, title, description, iconName, link, buttonText } = item;

            return (
              <li key={itemId}>
                <Link
                  href={getInternalDatoLinkHref({
                    link,
                    fallback: 'features',
                  })}
                  className={card()}
                >
                  <div>
                    <div className={iconWrapper()}>
                      <DynamicIcon name={iconName} size={32} className="transition-colors" />
                    </div>
                    <Heading variant="h3" as="h3" className={headingStyles()}>
                      {title}
                    </Heading>
                    <Text className={text()}>{description}</Text>
                  </div>
                  <Text className={moreButton()}>
                    {buttonText || 'More details'}
                    <ArrowRight size={16} className={moreArrow()} />
                  </Text>
                </Link>
              </li>
            );
          })}
        </ul>
      </Block>
    </div>
  );
};

const keyFeaturesBlockStyles = tv({
  slots: {
    base: 'text-text-primary',
    header: 'mb-8 flex flex-col',
    list: 'grid gap-y-4 md:grid-cols-2 md:gap-x-4 lg:grid-cols-4',
    iconWrapper: 'icon-wrapper mb-5 mr-5 grid size-8 place-items-center rounded-lg text-lightBlue-500',
    card: 'decoration-none flex cursor-pointer flex-col content-between rounded-lg border border-grey-300 bg-white bg-cover p-6 transition-all hover:-translate-y-1.5 hover:border-lightBlue-300 hover:shadow-[0_0_0_3px_theme(colors.lightBlue.400)] lg:p-8 [&_.icon-wrapper]:hover:text-blue-800 [&_.more-button_svg]:hover:translate-x-0.5',
    moreButton: 'more-button mb-0 mt-4 inline-flex items-center font-semibold text-lightBlue-600',
    moreArrow: 'ml-sm transition-transform',
    headingStyles: 'mb-2 text-baseLg text-grey-800 transition-colors',
    text: 'mb-0 leading-6 text-grey-500 lg:text-baseLg',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
        iconWrapper: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
    },
    blockAlignment: {
      left: {
        header: 'items-start text-left',
      },
      center: {
        header: 'items-center text-center',
      },
      right: {
        header: 'items-end text-right',
      },
    },
  },
  defaultVariants: {
    variant: 'light',
  },
});

export { KeyFeaturesBlock };
