import React from 'react';

import { AccordionBlock } from '@/blocks/accordion-block';
import { BentoGridBlock } from '@/blocks/bento-grid-block';
import { ContentMediaBlock } from '@/blocks/content-media-block';
import { CoverageBlock } from '@/blocks/coverage-block';
import { CtaBlock } from '@/blocks/cta-block';
import { GridBlock } from '@/blocks/grid-block';
import { HeaderBlock } from '@/blocks/header-block';
import { JobsBlock } from '@/blocks/jobs-block';
import { JumboTabsBlock } from '@/blocks/jumbo-tabs-block';
import { KeyFeaturesBlock } from '@/blocks/key-features-block';
import { LinkBankBlock } from '@/blocks/link-bank-block';
import { LogoPromoBlock } from '@/blocks/logo-promo-block';
import { OmniSearchBlock } from '@/blocks/omni-search-block';
import { PlansBlock } from '@/blocks/plans-block';
import { PostsBlock } from '@/blocks/posts-block';
import { PricingTiersBlock } from '@/blocks/pricing-tiers-block';
import { ProductFeaturesBlock } from '@/blocks/product-features-block';
import { ProductHeaderBlock } from '@/blocks/product-header-block';
import { RichTextBlock } from '@/blocks/rich-text-block';
import { SearchBlock } from '@/blocks/search-block';
import { SubscribeBlock } from '@/blocks/subscribe-block';
import { TeamBlock } from '@/blocks/team-block';
import { TestimonialBlock } from '@/blocks/testimonial-block';
import {
  AccordionBlockProps,
  ContentMediaBlockProps,
  CoverageBlockProps,
  CtaBlockProps,
  GridBlockProps,
  HeaderBlockProps,
  JobsBlockProps,
  JumboTabsBlockProps,
  KeyFeaturesBlockProps,
  LinkBankBlockProps,
  LogoPromoBlockProps,
  OmniSearchBlockProps,
  PlansBlockProps,
  PostsBlockProps,
  PricingTiersBlockProps,
  ProductFeaturesBlockProps,
  ProductHeaderBlockProps,
  RichTextBlockProps,
  SearchBlockProps,
  SubscribeBlockProps,
  TeamBlockProps,
  TestimonialBlockProps,
} from '@/types/block-types';
import { PageInterface } from '@/types/cms-types';
import { isProductionEnv } from '@/utils/helpers';

export interface ComponentsMapInterface {
  [key: string]: React.ElementType;
}

type BlocksInterface = Pick<PageInterface, 'blocks'>;

export type AllContentBlocks =
  | AccordionBlockProps
  | ContentMediaBlockProps
  | CoverageBlockProps
  | CtaBlockProps
  | GridBlockProps
  | HeaderBlockProps
  | JobsBlockProps
  | JumboTabsBlockProps
  | KeyFeaturesBlockProps
  | LinkBankBlockProps
  | LogoPromoBlockProps
  | OmniSearchBlockProps
  | PlansBlockProps
  | PostsBlockProps
  | PricingTiersBlockProps
  | ProductFeaturesBlockProps
  | ProductHeaderBlockProps
  | RichTextBlockProps
  | SearchBlockProps
  | SubscribeBlockProps
  | TeamBlockProps
  | TestimonialBlockProps;

export const componentsMap: ComponentsMapInterface = {
  AccordionBlockRecord: AccordionBlock,
  BentoGridBlockRecord: BentoGridBlock,
  ContentMediaBlockRecord: ContentMediaBlock,
  CoverageBlockRecord: CoverageBlock,
  CtaBlockRecord: CtaBlock,
  GridBlockRecord: GridBlock,
  HeaderBlockRecord: HeaderBlock,
  JobsBlockRecord: JobsBlock,
  JumboTabsBlockRecord: JumboTabsBlock,
  KeyFeaturesBlockRecord: KeyFeaturesBlock,
  LinkBankBlockRecord: LinkBankBlock,
  LogoPromoBlockRecord: LogoPromoBlock,
  OmniSearchBlockRecord: OmniSearchBlock,
  PlansBlockRecord: PlansBlock,
  PostsBlockRecord: PostsBlock,
  PricingTiersBlockRecord: PricingTiersBlock,
  ProductFeaturesBlockRecord: ProductFeaturesBlock,
  ProductHeaderBlockRecord: ProductHeaderBlock,
  RichTextBlockRecord: RichTextBlock,
  SearchBlockRecord: SearchBlock,
  SubscribeBlockRecord: SubscribeBlock,
  TeamBlockRecord: TeamBlock,
  TestimonialBlockRecord: TestimonialBlock,
};

const Placeholder = () => <div />;

const IS_PRODUCTION = isProductionEnv();

const ContentMatrix = ({ blocks }: BlocksInterface) => {
  if (!blocks?.length) return null;

  return (
    <div className="blocks">
      {blocks.map(({ hideInProduction, ...block }) => {
        let Block = componentsMap[block.__typename || ''];

        if (!Block) {
          Block = Placeholder;
        }

        if (IS_PRODUCTION && hideInProduction) return null;
        return <Block key={block.id || 'missing-key'} {...block} />;
      })}
    </div>
  );
};

export { ContentMatrix };
