import { ROUTING_SEARCH_URL, SAVED_ITEM_TYPE, SCHEDULE_SEARCH_URL, TRACKING_URL } from '@/lib/constants';
import { SavedItemModel } from '@/lib/models/saved-item/types';
import { logEvent } from '@/utils/logger';
import { searchStateToQueryParams } from '@/utils/route';

export const getSavedItemLink = (savedItem: SavedItemModel) => {
  try {
    if (savedItem.type === SAVED_ITEM_TYPE.ROUTE) {
      const query = searchStateToQueryParams(JSON.parse(savedItem.data));
      return `${ROUTING_SEARCH_URL}?${new URLSearchParams(query)}`;
    }
    if (savedItem.type === SAVED_ITEM_TYPE.SCHEDULE) {
      const query = JSON.parse(savedItem.data);
      return `${SCHEDULE_SEARCH_URL}?${new URLSearchParams(query)}`;
    }
    if (savedItem.type === SAVED_ITEM_TYPE.SHIPMENT) {
      const query = JSON.parse(savedItem.data);
      return `${TRACKING_URL}?${new URLSearchParams(query)}`;
    }
  } catch (e) {
    logEvent.error('Error getting savedItemLink', { e, savedItem });
  }
  return '';
};

export const byLabel = (label?: string) => {
  return (savedItem: SavedItemModel) => savedItem.label === label;
};

export const sortBySortOrder = (savedItems: SavedItemModel[], sortingIds: string[]) => {
  return savedItems.sort((a, b) => {
    // Get the index of each item in the sortingIds array
    const indexA = sortingIds.indexOf(a.id || '');
    const indexB = sortingIds.indexOf(b.id || '');

    // If both items are found in sortingIds, compare by their index
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If one is found in sortingIds and the other is not, prioritise the one found in sortingIds
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // Fall back to comparing by createdDate if id is not found in sortingIds
    const dateA = a.createdAt ? new Date(a.createdAt).getTime() : Infinity;
    const dateB = b.createdAt ? new Date(b.createdAt).getTime() : Infinity;

    return dateA - dateB;
  });
};
