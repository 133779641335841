import { tv } from '@/utils/styles';

import { Icon } from '../icon';

interface Props {
  icon: string;
}

const FeaturedIcon = ({ icon, ...props }: Props) => {
  const { base } = styles();
  return (
    <span className={base()} {...props}>
      <Icon name={icon} color="lightBlue.800" size="lg" />
    </span>
  );
};

const styles = tv({
  slots: {
    base: 'mb-6 grid h-16 w-16 place-items-center rounded-full bg-white',
  },
});

export { FeaturedIcon };
