export const validateMbl = (mbl: string) => {
  // Patterns taken from https://support.vizionapi.com/bill-of-lading-formatting
  const patterns = [
    /^\d{10}$/, // 10 numbers
    /^\d{12}$/, // 12 numbers
    /^[A-Z0-9]{12}$/i, // 12 alphanumeric characters
    /^HLCU[A-Z]{2}[A-Z0-9]{1}[A-Z0-9]{9}$/i, // HLCU + 2 letters + (1 letter or 1 number) + 9 alphanumeric characters
    /^([A-Z]{3}\d{7}|[A-Z]{4}\d{6})$/i, // 3 letters + 7 numbers or 4 letters + 6 numbers
    /^\d{9}$/, // 9 numbers
    /^MEDU[A-Z0-9]{2}\d{6}$/i, // MEDU + 2 alphanumeric characters + 6 numbers
    /^SNKO\d{12}$/i, // SNKO + 12 numbers
    /^\d{3}[A-Z]{2}\d{5}$/i, // 3 numbers + 2 letters + 5 numbers
    /^[A-Z]\d{9}$/i, // 1 letter + 9 numbers
    /^[A-Z]{3,4}\d{4,10}$/i, // 3 to 4 letters + 4 to 10 numbers
  ];

  return patterns.some((pattern) => pattern.test(mbl));
};
