import { atom, selector } from 'recoil';

import { getPortsClosestToSearchLocation, shouldShowPort } from '@/components/views/port-insights/map/utils';
import {
  AIRPORT,
  DEFAULT_SEARCH_RADIUS_IN_KM,
  FILTER_ALL,
  MODE_AIR,
  MODE_WATER,
  PORT_CONNECTION_TYPES,
  SEAPORT,
} from '@/lib/constants';
import { RouteSummaryModel } from '@/lib/models/route-summary/types';
import {
  CarrierSearchInterface,
  MapRouteInterface,
  PortInterface,
  PortsFiltersInterface,
  PortsSearchParams,
} from '@/types';
import { PortDataApiInterface } from '@/types/api-types';

import { mapBoundsState } from './map';
import { userSettingsState } from './user';

const defaultPortsFilters: PortsFiltersInterface = {
  transportType: FILTER_ALL,
  carrier: undefined,
};

export const portsSearchParamsState = atom<PortsSearchParams>({
  key: 'portsSearchParamsState',
  default: { origin: null, destination: null },
});

export const portsMapLocationsState = atom<MapRouteInterface | null>({
  key: 'portsMapLocationsState',
  default: null,
});

export const portsState = atom<PortInterface[]>({
  key: 'portsState',
  default: [],
});

/**
 * Used for the initial ports count when you are browsing the map
 */
export const portsWithinViewPortState = selector<PortInterface[]>({
  key: 'portsWithinViewPortState',
  get: ({ get }) => {
    const ports = get(portsState);
    const portsFilters = get(portsFiltersState);
    const searchParams = get(portsSearchParamsState);
    const searchRadius = get(searchRadiusState);
    const mapBounds = get(mapBoundsState);
    const { distanceUnits } = get(userSettingsState);

    const portsWithinBounds = ports.filter((port) => {
      if (mapBounds?.contains(port.location) && shouldShowPort(port, portsFilters)) {
        return port;
      }

      return null;
    });

    const portsClosestToSearchLocation = getPortsClosestToSearchLocation(
      portsWithinBounds,
      searchParams.origin,
      searchRadius,
      distanceUnits?.value,
    );

    return portsClosestToSearchLocation;
  },
});

export const selectedPortsState = atom<{
  origin: PortInterface | null;
  destination: PortInterface | null;
  routeSummary?: RouteSummaryModel | null;
}>({
  key: 'selectedPortsState',
  default: {
    origin: null,
    destination: null,
    routeSummary: null,
  },
});

export const portsFiltersState = atom({
  key: 'portsFiltersState',
  default: defaultPortsFilters,
});

export const selectedPortDetailsState = atom<PortDataApiInterface | null>({
  key: 'selectedPortDetailsState',
  default: null,
});

export const hoveredPortState = atom<PortInterface | null>({
  key: 'hoveredPortState',
  default: null,
});

export const locationAutocompleteValueState = atom<string>({
  key: 'locationAutocompleteValueState',
  default: '',
});

export const searchRadiusState = atom<string>({
  key: 'searchRadiusState',
  default: DEFAULT_SEARCH_RADIUS_IN_KM,
});

export const isSearchingAllPortsState = atom<boolean>({
  key: 'isSearchingAllPortsState',
  default: false,
});

export const isSearchingPortsState = atom<boolean>({
  key: 'isSearchingPortsState',
  default: false,
});

export const isSearchingRoutesSummaryState = atom<boolean>({
  key: 'isSearchingRoutesSummaryState',
  default: false,
});

export const carriersIsOpenState = atom<boolean>({
  key: 'carriersIsOpenState',
  default: false,
});

/**
 * Get the ports that are to be rendered on the map based on the active filters
 */
export const visiblePortsState = selector({
  key: 'visiblePortsState',
  get: ({ get }) => {
    const ports = get(portsState);
    const portsFilters = get(portsFiltersState);

    return ports.filter((port) => {
      if (shouldShowPort(port, portsFilters)) {
        return port;
      }

      return null;
    });
  },
});

export const filteredPortsState = selector<PortInterface[]>({
  key: 'filteredPortsState',
  get: ({ get }) => {
    const searchParams = get(portsSearchParamsState);
    const searchRadius = get(searchRadiusState);
    const visiblePorts = get(visiblePortsState);
    const { distanceUnits } = get(userSettingsState);

    return getPortsClosestToSearchLocation(visiblePorts, searchParams.origin, searchRadius, distanceUnits?.value);
  },
});

/**
 * Will either display all ports or the connections for a port
 */
export const portsOrConnectionsState = selector<(PortInterface | undefined)[]>({
  key: 'portsOrConnectionsState',
  get: ({ get }) => {
    const connections = get(connectionsState);
    const connectionsVisible = get(showConnectionsState);
    const visiblePorts = get(visiblePortsState);

    if (connectionsVisible) return connections;

    return visiblePorts;
  },
});

/**
 * Get the connections for a port
 */
export const connectionsState = selector<PortInterface[]>({
  key: 'connectionsState',
  get: ({ get }) => {
    const showConnections = get(showConnectionsState);

    if (!showConnections) return [];

    const connectionsDirection = get(connectionsDirectionState);
    const selectedPortDetails = get(selectedPortDetailsState);
    const portsFilters = get(portsFiltersState);
    const ports = get(portsState);

    const links =
      connectionsDirection === PORT_CONNECTION_TYPES.INBOUND
        ? selectedPortDetails?.linksIncoming
        : selectedPortDetails?.links;

    const carrierId = portsFilters?.carrier?.id;

    const filteredConnections =
      links
        // Filter based on any active active carrier filter
        ?.filter((link) => (carrierId ? link.carrierIds.includes(carrierId) : true))
        // Find the port for each link
        .map((link) => {
          const port = ports.find((portItem) => portItem.id === link.targetId);
          return link.flags ? { ...port, flags: link.flags } : port;
        })
        // Filter ports based on transport type
        .filter((port): port is PortInterface => {
          return (
            port !== undefined && (portsFilters.transportType === port.type || portsFilters.transportType === 'all')
          );
        }) || [];

    return filteredConnections;
  },
});

/**
 * Get the number of connections for a port
 *
 * Will return with the total connections for a port or if
 * a carrier filter is active it will return the
 * total connections for that carrier
 */
export const selectedPortConnectionsCountState = selector({
  key: 'selectedPortConnectionsCountState',
  get: ({ get }) => {
    const selectedPorts = get(selectedPortsState);
    const connectionsDirection = get(connectionsDirectionState);
    const activeCarrierFilter = get(activeCarrierFilterState);

    const portToUse = activeCarrierFilter || selectedPorts?.origin;

    return connectionsDirection === PORT_CONNECTION_TYPES.INBOUND ? portToUse?.linkIncomingCount : portToUse?.linkCount;
  },
});

/**
 * Whether the connections are inbound or outbound
 */
export const connectionsDirectionState = atom<
  typeof PORT_CONNECTION_TYPES.INBOUND | typeof PORT_CONNECTION_TYPES.OUTBOUND
>({
  key: 'connectionsDirectionState',
  default: PORT_CONNECTION_TYPES.OUTBOUND,
});

/**
 * If connections are being shown on the port insights map
 */
export const showConnectionsState = atom<boolean>({
  key: 'showConnectionsState',
  default: false,
});

export const globalPortsReset = selector({
  key: 'globalPortsReset',
  get: () => ({}), // Not used, but required by Recoil
  set: ({ reset }) => {
    reset(selectedPortsState);
    reset(selectedPortDetailsState);
    reset(showConnectionsState);
    reset(carriersIsOpenState);
    reset(hoveredPortState);
  },
});

export const portsFiltersCarriersState = atom<CarrierSearchInterface[]>({
  key: 'portsFiltersCarriersState',
  default: [],
});

// Helper to display the correct transport mode and carrier
// combobox label based on the selected transport type
export const portsTransportModesText = selector({
  key: 'portsTransportModesText',
  get: ({ get }) => {
    const { transportType } = get(portsFiltersState);
    const transportModeAndLabelMap: { [key: string]: { transportMode: string; carrierComboboxLabel: string } } = {
      [AIRPORT]: { transportMode: MODE_AIR, carrierComboboxLabel: 'Airlines' },
      [SEAPORT]: { transportMode: MODE_WATER, carrierComboboxLabel: 'Ocean carriers' },
    };

    return transportModeAndLabelMap[transportType] || { transportMode: '', carrierComboboxLabel: '' };
  },
});

/**
 * Gets the details for the current carrier
 * in the filters from the selected port
 */
export const activeCarrierFilterState = selector({
  key: 'activeCarrierFilterState',
  get: ({ get }) => {
    const portsFilters = get(portsFiltersState);
    const selectedPortDetails = get(selectedPortDetailsState);

    const activeCarrierIdFilter = portsFilters?.carrier?.id;
    const activeCarrierFilter = selectedPortDetails?.carriersAtPort?.find(
      (carrier) => carrier.id === activeCarrierIdFilter,
    );

    if (activeCarrierFilter) {
      return {
        ...activeCarrierFilter,
        linkCount: Object.entries(activeCarrierFilter.links)?.length,
        linkIncomingCount: Object.entries(activeCarrierFilter.linksIncoming)?.length,
      };
    }

    return activeCarrierFilter;
  },
});
