import styled from 'styled-components';
import { variant } from 'styled-system';

import { Block } from '@/components/block';
import { Box } from '@/components/box';
import { ButtonGroup } from '@/components/button-group';
import { Heading } from '@/components/heading';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { CtaBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const CtaBlock = ({
  id,
  heading,
  body,
  isCentered = false,
  buttons = [],
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  variant: blockVariant = 'light',
}: CtaBlockProps) => {
  const { row, contentCol, headingCol, buttonGroup, richText } = ctaBlockStyles({ isCentered });

  return (
    <Wrapper variant={blockVariant}>
      <Block id={id} className="py-12 xl:p-24" noTopPadding={noTopPadding}>
        {/* Separate pre-heading so the title and the body line up regardless */}
        <div className={row()}>
          <div className={headingCol()}>
            {preHeading && (
              <Text variant={preHeadingVariant} className="pre-heading mb-4">
                {preHeading}
              </Text>
            )}
          </div>
        </div>
        <div className={row()}>
          <div className={headingCol()}>
            {heading && (
              <Heading variant={headingVariant} as="h2" className="mb-md">
                {heading}
              </Heading>
            )}
          </div>
          <div className={contentCol()}>
            {body && <RichText className={richText()}>{body}</RichText>}

            {buttons && <ButtonGroup buttons={buttons} className={buttonGroup()} />}
          </div>
        </div>
      </Block>
    </Wrapper>
  );
};

const ctaBlockStyles = tv({
  slots: {
    row: 'mx-auto block md:max-lg:w-10/12 lg:grid lg:max-w-none lg:grid-cols-2 lg:justify-normal lg:gap-lg',
    contentCol: 'flex flex-col justify-center md:max-lg:col-start-2 md:max-lg:col-end-12',
    headingCol: 'md:max-lg:col-start-2 md:max-lg:col-end-12',
    buttonGroup: 'mt-lg items-start justify-start',
    richText: null,
  },
  variants: {
    isCentered: {
      true: {
        row: 'justify-center lg:block lg:grid-cols-none lg:justify-center lg:gap-none',
        contentCol: 'mx-auto lg:w-1/2',
        headingCol: 'mx-auto text-center lg:w-2/3',
        buttonGroup: 'items-center justify-center',
        richText: 'mx-auto text-center',
      },
    },
  },
});

const ctaBlockVariants = variant({
  scale: 'header',
  variants: {
    light: {
      '.pre-heading': {
        color: 'grey.500',
      },
    },
    dark: {
      color: 'white',
      backgroundColor: 'blue.600',

      '.pre-heading': {
        color: 'grey.300',
      },
      p: {
        color: 'grey.200',
      },
    },
  },
});

const Wrapper = styled(Box)<any>`
  ${ctaBlockVariants}
`;

export { CtaBlock };
