import Link from 'next/link';

import { tv } from '@/utils/styles';

interface Props {
  heading: string;
  links: { text: string; href: string }[];
}

const Links = ({ heading, links }: Props) => {
  const { base, headingStyles, ul, li, link } = styles();

  return (
    <div className={base()}>
      <h3 className={headingStyles()}>{heading}:</h3>
      <ul className={ul()}>
        {links.map(({ text, href }) => (
          <li className={li()} key={text}>
            <Link href={href} className={link()}>
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'mt-2 text-baseSm text-white',
    headingStyles: 'font-medium md:inline-block',
    ul: 'gap-x-1 text-baseSm text-white md:ml-1 md:inline-flex',
    li: 'inline-block after:content-[","] last:after:hidden max-md:mr-1 max-md:last:mr-0',
    link: 'underline decoration-white/20 underline-offset-2 hover:decoration-white',
  },
});

export { Links };
