import { request } from '@/utils/api/request';
import { getMailingListUrl } from '@/utils/helpers/api-urls';

export const MAILING_LIST_STATUS = {
  SUBSCRIBED: 'subscribed',
  CLEANED: 'cleaned',
  PENDING: 'pending',
  TRANSACTIONAL: 'transactional',
};

interface SubscribeInterface {
  email: string;
  name?: string;
  phone?: string;
  usagePurpose?: string;
  isFreightForwarder?: boolean;
  enquiryType?: string;
  country?: string;
  businessType?: string;
  businessName?: string;
}

interface MailingList {
  subscribe: (args: SubscribeInterface) => Promise<void>;
}

export const mailingListProvider: MailingList = {
  subscribe: async (data) => {
    const config = {
      body: {
        ...data,
        status: MAILING_LIST_STATUS.SUBSCRIBED,
      },
    };

    await request(getMailingListUrl(), config);
  },
};
