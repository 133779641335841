import { motion } from 'framer-motion';
import { includes, pull } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Icon } from '@/components/icon';
import { RichText } from '@/components/rich-text';
import { useEffectOnlyRunOnFirstMount } from '@/hooks/use-effect-only-on-first-mount';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

export interface AccordionItemProps {
  id: string | number;
  heading: string;
  body: string | React.ReactNode | React.ReactNode[];
  topic?: string;
}

interface AccordionProps {
  allowMultipleExpanded?: boolean;
  preExpandFirstItem?: boolean;
  id: string | number;
  items: AccordionItemProps[];
}

const Accordion = ({ items, preExpandFirstItem, allowMultipleExpanded, id, ...props }: AccordionProps) => {
  const [openItems, setOpenItem] = useState<Array<string | number>>([]);

  const isFirstRender = useEffectOnlyRunOnFirstMount();

  useEffect(() => {
    if (isFirstRender && preExpandFirstItem && items[0]) {
      setOpenItem([items[0].id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preExpandFirstItem, isFirstRender, id]);

  const { button, listItem, content, iconWrapper } = styles();

  return (
    <ul {...props}>
      {items.map((item, index) => {
        const { heading, body } = item;
        const key = item.id;
        const isOpen = includes(openItems, key);

        const trackOpenAccordion = () => {
          trackUser.event('Open Accordion', {
            title: item.heading,
            id: item.id,
            topic: item.topic || '',
          });
        };

        const openSingle = () => {
          if (isOpen) {
            setOpenItem([...pull(openItems, key)]);
          } else {
            setOpenItem([key]);
            // Analytics Event
            trackOpenAccordion();
          }
        };

        const openMultiple = () => {
          if (isOpen) {
            setOpenItem([...pull(openItems, key)]);
          } else {
            setOpenItem([...openItems, key]);
            // Analytics Event
            trackOpenAccordion();
          }
        };

        const accordionPanelId = `panel-${key}`;
        const accordionItemId = `item-${key}`;

        return (
          <li className={listItem()} key={key}>
            <button
              aria-controls={accordionPanelId}
              aria-expanded={isOpen}
              id={accordionItemId}
              onClick={allowMultipleExpanded ? openMultiple : openSingle}
              type="button"
              className={button()}
            >
              <span>{heading}</span>
              <div className={iconWrapper()}>
                <Icon name="down" transform={isOpen ? 'rotate(-180deg)' : ''} />
              </div>
            </button>
            <motion.div
              animate={isOpen ? 'open' : 'collapsed'}
              aria-hidden={!isOpen}
              aria-labelledby={accordionItemId}
              exit="collapsed"
              id={accordionPanelId}
              initial="collapsed"
              role="region"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.26, ease: 'easeInOut' }}
              data-testid={`accordion-content-${index}`}
            >
              <div className={content()}>
                <RichText>{body}</RichText>
              </div>
            </motion.div>
          </li>
        );
      })}
    </ul>
  );
};

const styles = tv({
  slots: {
    button:
      'accordion-button flex w-full items-center bg-white p-5 text-left text-baseLg font-semibold text-text-primary transition-all hover:text-lightBlue-700 [&_svg]:fill-grey-700',
    listItem: 'overflow-hidden rounded-lg border border-grey-200 bg-white hover:border-grey-300 [&+&]:mt-sm',
    content: 'p-5 pt-0 text-left',
    iconWrapper: 'ml-auto shrink-0 justify-center',
  },
});

export { Accordion };
