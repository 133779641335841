import { motion } from 'framer-motion';
import { get } from 'lodash';
import CountUp from 'react-countup';

import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { DATA_IMAGES } from '@/lib/constants/styles';
import { colors } from '@/theme/constants/colors';
import { cn, tv } from '@/utils/styles';

export interface CoverageItemProps {
  __typename?: string;
  heading?: string;
  subHeading?: string;
  stat?: number;
  icon: string;
  routeTypeColor: string;
  image?: {
    url: string;
  };
  id?: string;
  animateItem?: boolean;
  index: number;
  className?: string;
}

const STAT_DELAY = 1.2;

const wrapperMotionVariants = {
  visible: (delay: number) => ({
    opacity: 1,
    scale: 1,
    transition: { delay, duration: 1, ease: 'easeInOut', type: 'spring' },
  }),
  hidden: { opacity: 0, scale: 0 },
};

const statWrapperMotionVariants = {
  visible: (delay: number) => ({
    x: 0,
    transition: { duration: 0.6, delay: delay + 0.5, ease: 'easeInOut', type: 'spring' },
  }),
  hidden: { x: '-100%' },
};

const iconMotionVariants = {
  visible: (delay: number) => ({
    scale: 1,
    opacity: 1,
    transition: { duration: 0.6, delay: delay + 0.8, ease: 'easeInOut', type: 'spring' },
  }),
  hidden: { scale: 0.2, opacity: 0 },
};

const statMotionVariants = {
  visible: (delay: number) => ({
    scale: 1,
    opacity: 1,
    transition: { duration: 0.4, delay: delay + STAT_DELAY, ease: 'easeInOut', type: 'spring' },
  }),
  hidden: { scale: 0.5, opacity: 0 },
};

const headerMotionVariants = {
  visible: (delay: number) => ({
    scale: 1,
    opacity: 1,
    transition: { duration: 0.6, delay: delay + 1.6, ease: 'easeInOut', type: 'spring' },
  }),
  hidden: { scale: 0.9, opacity: 0 },
};

const CoverageItem = ({
  heading,
  stat,
  icon,
  routeTypeColor,
  subHeading,
  image,
  animateItem = true,
  index,
  className,
  ...props
}: CoverageItemProps) => {
  const { base, inner, statWrapper, statItem, divider } = coverageItemStyles();

  const itemDelay = index * 0.1;

  return (
    <motion.div
      initial="hidden"
      animate={animateItem ? 'visible' : 'hidden'}
      variants={wrapperMotionVariants}
      custom={itemDelay}
      className={base({ className })}
      {...props}
    >
      <div
        className={cn(inner(), { 'bg-[#eaf2f7]': stat })}
        style={{
          backgroundImage: `url(${image?.url || ''})`,
        }}
      >
        <motion.div
          initial="hidden"
          animate={animateItem ? 'visible' : 'hidden'}
          variants={statWrapperMotionVariants}
          custom={itemDelay}
          className="w-full"
        >
          <div
            className={statWrapper()}
            style={{
              backgroundColor: get(colors, routeTypeColor),
            }}
          >
            {stat && (
              <motion.div
                initial="hidden"
                animate={animateItem ? 'visible' : 'hidden'}
                variants={statMotionVariants}
                custom={itemDelay}
                className={statItem()}
              >
                {animateItem && <CountUp end={stat} duration={2} delay={STAT_DELAY + index * 0.2} />}
              </motion.div>
            )}
            <motion.div
              initial="hidden"
              animate={animateItem ? 'visible' : 'hidden'}
              variants={iconMotionVariants}
              custom={itemDelay}
              className="ml-auto"
            >
              <Icon
                name={icon}
                color="white"
                size={undefined}
                className="[&_svg]:h-md [&_svg]:w-md [&_svg]:sm:h-lg [&_svg]:sm:w-lg"
              />
            </motion.div>
          </div>
        </motion.div>
      </div>
      <motion.div
        initial="hidden"
        animate={animateItem ? 'visible' : 'hidden'}
        variants={headerMotionVariants}
        custom={itemDelay}
      >
        {heading && (
          <Heading variant="h3" className="mb-0 text-baseLg sm:text-md">
            {heading}
          </Heading>
        )}
        {subHeading && (
          <Text variant="secondary" className="m-0 font-semibold !text-grey-300">
            {subHeading}
          </Text>
        )}
      </motion.div>
      <span className={divider()} style={{ backgroundImage: `url(${DATA_IMAGES.STRIPED_DARK})` }} />
    </motion.div>
  );
};

const coverageItemStyles = tv({
  slots: {
    base: 'mx-auto flex max-w-[320px] flex-col justify-center text-center',
    inner:
      'z-2 mx-auto mb-4 flex h-36 w-36 items-center justify-center overflow-hidden rounded-full bg-grey-200 bg-contain shadow-[0_0_0_7px_rgba(255,255,255,1)] sm:mb-6 sm:h-44 sm:w-44',
    statWrapper:
      'flex h-11 w-full -translate-x-4 items-center justify-between rounded-full px-3 py-2 pl-8 text-baseSm sm:h-[60px] sm:-translate-x-6 sm:pl-12',
    statItem: 'flex rounded-default bg-yellow px-3 py-1 text-baseSm font-semibold leading-none sm:text-[20px]',
    // Divider used in coverage block
    divider:
      'divider absolute left-full top-[60px] top-[calc(44px+theme(spacing.1))] z-1 -ml-[5px] hidden h-[44px] w-[200px] w-full bg-grey-100 bg-[length:5px] opacity-60 min-[400px]:block sm:top-[60px] sm:h-[60px] md:w-[240px] xl:!block',
  },
});

export { CoverageItem };
