import { useMemo } from 'react';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  // Create an array of certain length and set the elements
  // within it from start value to end value.
  return Array.from({ length }, (_, i) => i + start);
};

export const ELLIPSIS_DOTS = '...';

interface UsePaginationProps {
  totalCount: number;
  perPage: number;
  siblingCount?: number;
  pageNumber: number;
}

const usePagination = ({ totalCount, perPage, siblingCount = 1, pageNumber }: UsePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / perPage);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*ELLIPSIS_DOTS
    const totalPageNumbers = siblingCount + 5;

    // Case 1:
    // If the number of pages is less than the page numbers we want to show in our
    // pagination component, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate left and right sibling index and make sure
    // they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(pageNumber - siblingCount, 1);
    const rightSiblingIndex = Math.min(pageNumber + siblingCount, totalPageCount);

    // We do not show dots just when there is just one page number to be inserted
    // between the extremes of sibling and the page limits i.e 1 and totalPageCount.
    // Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageNumber = 1;
    const lastPageNumber = totalPageCount;

    // Case 2: No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, ELLIPSIS_DOTS, totalPageCount];
    }

    // Case 3: No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageNumber, ELLIPSIS_DOTS, ...rightRange];
    }

    // Case 4: Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);
      return [firstPageNumber, ELLIPSIS_DOTS, ...middleRange, ELLIPSIS_DOTS, lastPageNumber];
    }

    return [];
  }, [totalCount, perPage, siblingCount, pageNumber]);

  return paginationRange;
};

export { usePagination };
