import { Color, Position } from 'deck.gl/typed';
import { clamp, range, sortBy } from 'lodash';

import {
  AIRPORT,
  CONNECTIONS_ARROW_ICON_SIZE,
  DISTANCE_UNITS,
  FLAGS,
  PORT_CONNECTION_TYPES,
  SEAPORT,
} from '@/lib/constants';
import { colors } from '@/theme/constants';
import { DistanceUnitsType, LocationInterface, PortInterface, PortsFiltersInterface } from '@/types';
import { hexToRgba } from '@/utils/helpers';

import { MarkerDotProps } from '../../../map-markers/marker-dot';
import { zoomToOptions } from './config';

const shouldShowPort = (port: PortInterface, portsFilters: PortsFiltersInterface): boolean => {
  // Return filtered port by type
  return portsFilters.transportType === port.type || portsFilters.transportType === 'all';
};

const getPortsClosestToSearchLocation = (
  visiblePorts: PortInterface[],
  searchOrigin: LocationInterface | null,
  searchRadius: string,
  distanceUnits?: DistanceUnitsType,
): PortInterface[] => {
  if (!searchOrigin) return visiblePorts;

  const portsClosestToSearchLocation = visiblePorts.filter((port) => {
    const distanceFromSearchOrigin = getDistanceFromSearchOrigin(port, searchOrigin);

    const searchOriginRadius =
      distanceUnits === DISTANCE_UNITS.KM ? Number(searchRadius) * 1000 : Number(searchRadius) * 1609.34;

    if (distanceFromSearchOrigin < searchOriginRadius) {
      return port;
    }

    return null;
  });

  const portsSortedByDistanceFromSearchOrigin = sortBy(portsClosestToSearchLocation, (port) =>
    getDistanceFromSearchOrigin(port, searchOrigin),
  );

  return portsSortedByDistanceFromSearchOrigin;
};

const getDistanceFromSearchOrigin = (
  port: PortInterface,
  searchOrigin: LocationInterface | PortInterface,
): number /* Meters */ => {
  try {
    if (!searchOrigin.location) return 0;

    const portLatLng = new google.maps.LatLng(port.location.lat, port.location.lng);
    const searchOriginLatLng = new google.maps.LatLng(searchOrigin.location);

    const distanceFromSearchOrigin = google.maps.geometry.spherical.computeDistanceBetween(
      searchOriginLatLng,
      portLatLng,
    );

    return distanceFromSearchOrigin;
  } catch (error) {
    return 0;
  }
};

/**
 * Convert meters to km or miles
 */
const getDistanceInSelectedUnits = (distanceInMeters: number, distanceUnits: DistanceUnitsType): number => {
  const distanceInKm = distanceInMeters / 1000;
  const distanceInMi = distanceInMeters * 0.000621;

  return distanceUnits === DISTANCE_UNITS.KM ? distanceInKm : distanceInMi;
};

const centerMapToPort = (selectedPort: PortInterface | null, mapInstance: google.maps.Map): void => {
  if (!selectedPort) return;

  mapInstance?.setCenter(selectedPort.location);
  mapInstance?.setZoom(zoomToOptions.selectedPort.zoom);
  mapInstance?.panBy(zoomToOptions.selectedPort.panBy.x, zoomToOptions.selectedPort.panBy.y);
};

/**
 * Convert km or miles to meters
 */
const getSearchOriginRadius = (distance: string, distanceUnits: DistanceUnitsType): number /* Meters */ => {
  const distanceFromKm = Number(distance) * 1000;
  const distanceFromMi = Number(distance) * 1609.34;

  return distanceUnits === DISTANCE_UNITS.KM ? distanceFromKm : distanceFromMi;
};

const convertKmToMiles = (distanceInKm: number, distanceUnits: DistanceUnitsType): string => {
  const distance = distanceUnits === DISTANCE_UNITS.KM ? distanceInKm : distanceInKm * 0.621371;
  return `${distance.toFixed(2)} ${distanceUnits}`;
};

/**
 * Get the points of a circle around a latLng,
 * accounting for the curvature of the earth
 *
 * @param latLng - The center point of the circle
 * @param radiusInMeters - The radius of the circle in meters
 * @param numPoints - The number of points to use to draw the circle
 *
 * @returns An array of points that make up the circle
 */
const getGeodesicRadiusCircle = (latLng: [number, number], radiusInMeters: number, numPoints = 360): number[][] => {
  const [lat, lng] = latLng;
  const latLngCenter = new google.maps.LatLng(lat, lng);
  const points: number[][] = [];

  range(numPoints).forEach((i) => {
    const heading = (360 / numPoints) * i;
    const latLngPoint = google.maps.geometry.spherical.computeOffset(latLngCenter, radiusInMeters, heading);
    points.push([latLngPoint.lng(), latLngPoint.lat()]);
  });

  // Close the circle
  points.push(points[0]);
  return points;
};

const AIRPORT_SIZE_SMALL = 10;
const AIRPORT_SIZE_MEDIUM = 50;

const SEAPORT_SIZE_SMALL = 2;
const SEAPORT_SIZE_MEDIUM = 10;

const getWebGlMarkerSize = (linkCount: number, type: string): number => {
  if (type === 'airport') {
    if (linkCount <= AIRPORT_SIZE_SMALL) return 4;
    if (linkCount <= AIRPORT_SIZE_MEDIUM) return 6;
  }

  if (type === 'seaport') {
    if (linkCount <= SEAPORT_SIZE_SMALL) return 4;
    if (linkCount <= SEAPORT_SIZE_MEDIUM) return 6;
  }

  return 10;
};

// Marker used for the non-webGL versions, used for selected marker and the legend
const getMarkerSize = (linkCount: number, type: string): MarkerDotProps['size'] => {
  if (type === 'airport') {
    if (linkCount <= AIRPORT_SIZE_SMALL) return 'sm';
    if (linkCount <= AIRPORT_SIZE_MEDIUM) return 'md';
  }

  if (type === 'seaport') {
    if (linkCount <= SEAPORT_SIZE_SMALL) return 'sm';
    if (linkCount <= SEAPORT_SIZE_MEDIUM) return 'md';
  }

  return 'xl';
};

const SCATTER_POINT_COLOR_MAP = {
  [FLAGS.JONES_ACT_INVALID]: {
    fill: hexToRgba(colors.states.error.borderColor),
    line: hexToRgba(colors.states.error.color),
  },
  [AIRPORT]: {
    fill: hexToRgba(colors.transport.plane),
    line: hexToRgba('#78A73E'),
  },
  [SEAPORT]: {
    fill: hexToRgba('#6DC9E5'),
    line: hexToRgba(colors.lightBlue[500]),
  },
};

const getScatterPointColors = (port: PortInterface, type: 'fill' | 'line'): Color => {
  const key = port.flags?.includes(FLAGS.JONES_ACT_INVALID) ? FLAGS.JONES_ACT_INVALID : port.type;

  return SCATTER_POINT_COLOR_MAP[key][type];
};

const ARC_COLOR_MAP = {
  [PORT_CONNECTION_TYPES.OUTBOUND]: {
    source: colors.maps.connectionsArcSource,
    target: colors.maps.connectionsArcTarget,
  },
  [PORT_CONNECTION_TYPES.INBOUND]: {
    source: colors.maps.connectionsArcTarget,
    target: colors.maps.connectionsArcSource,
  },
};

const getArcColors = (
  connectionsDirection: typeof PORT_CONNECTION_TYPES.INBOUND | typeof PORT_CONNECTION_TYPES.OUTBOUND,
  type: 'source' | 'target',
) => {
  return ARC_COLOR_MAP[connectionsDirection][type] || colors.maps.connectionsArcSource;
};

/**
 * Get the middle point of the connection arc
 * @param selectedPort
 * @param port - the port to get the middle point of the connection arc to
 */
const getMiddlePointOfConnectionArc = (selectedPort: PortInterface | null, port: PortInterface | null): Position => {
  if (!selectedPort || !port) return [0, 0];

  const [startLng, startLat] = [selectedPort.location.lng, selectedPort.location.lat];
  const [endLng, endLat] = [port.location.lng, port.location.lat];

  const startLatLng = new google.maps.LatLng(startLat, startLng);
  const endLatLng = new google.maps.LatLng(endLat, endLng);

  // Calculate the midpoint of the great circle path between the start and end points
  const midpointLatLng = google.maps.geometry.spherical.interpolate(startLatLng, endLatLng, 0.5);

  // Convert the midpoint latitudes and longitudes back to degrees
  const midpointLatDegrees = midpointLatLng.lat();
  const midpointLngDegrees = midpointLatLng.lng();

  return [midpointLngDegrees, midpointLatDegrees];
};

/**
 * Calculate the size of the connections direction icon based on the length of the arc
 * @param selectedPort
 * @param port - the port that will be connected to
 */
const getDirectionArrowIconSize = (selectedPort: PortInterface, port: PortInterface) => {
  const MAX_DISTANCE_IN_KMS = 2000;
  const MAX_SIZE_IN_PIXELS = CONNECTIONS_ARROW_ICON_SIZE;
  const MIN_SIZE_IN_PIXELS = 7;
  const distance = getDistanceFromSearchOrigin(selectedPort, port);

  const size = (distance / 1000 / MAX_DISTANCE_IN_KMS) * MAX_SIZE_IN_PIXELS;

  return clamp(size, MIN_SIZE_IN_PIXELS, MAX_SIZE_IN_PIXELS);
};
/**
 * Icon used for the connections direction
 * Needs to be a string as it's used in the SVG
 */
const caretIcon = (fillColor: string) => {
  return `
      <svg width="128" height="128" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M123.428 123.857H4.5717C3.77977 123.857 3.00141 123.651 2.31289 123.26C1.62436 122.869 1.04928 122.306 0.643995 121.625C0.238713 120.945 0.0171292 120.171 0.000953869 119.379C-0.0152214 118.587 0.174567 117.805 0.551724 117.109L59.98 7.39482C60.3722 6.67052 60.9528 6.06562 61.6604 5.64408C62.368 5.22253 63.1763 5 64 5C64.8237 5 65.632 5.22253 66.3396 5.64408C67.0472 6.06562 67.6278 6.67052 68.02 7.39482L127.448 117.109C127.825 117.805 128.015 118.587 127.999 119.379C127.983 120.171 127.761 120.945 127.356 121.625C126.951 122.306 126.376 122.869 125.687 123.26C124.999 123.651 124.22 123.857 123.428 123.857Z" fill="${fillColor}" />
</svg>`;
};

/**
 * Converts a SVG string to a data URL
 */
const svgToDataURL = (svg: string) => {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
};

export {
  caretIcon,
  centerMapToPort,
  convertKmToMiles,
  getArcColors,
  getDirectionArrowIconSize,
  getDistanceFromSearchOrigin,
  getDistanceInSelectedUnits,
  getGeodesicRadiusCircle,
  getMarkerSize,
  getMiddlePointOfConnectionArc,
  getPortsClosestToSearchLocation,
  getScatterPointColors,
  getSearchOriginRadius,
  getWebGlMarkerSize,
  shouldShowPort,
  svgToDataURL,
};
