import { themeGet } from '@styled-system/theme-get';
import { map } from 'lodash';
import styled from 'styled-components';

import { Box } from '@/components/box';
import { Button } from '@/components/button';
import { Flex } from '@/components/flex';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { VisuallyHidden } from '@/components/visually-hidden';
import { CREATE_ACCOUNT_QUERY_STRING, SHOULD_CREATE_ACCOUNT, SIGN_IN_PAGE_URL } from '@/lib/constants';
import { PricingTiersBlockProps, PricingTiersFeatureTierInterface } from '@/types/block-types';
import { media } from '@/utils/media';
import { cn } from '@/utils/styles';

// @TODO: Should these colours have a home?
const COLUMN_HIGHLIGHT_COLOR = '#f2faff';

const PricingTiers = ({ id, featureCategories, ...props }: PricingTiersBlockProps) => {
  const renderFeatureCell = (feature: PricingTiersFeatureTierInterface) => {
    if (feature.text) {
      const isBetaText: boolean = feature.text.includes('Included while in Beta');

      return (
        <Text
          variant={!isBetaText ? 'secondary' : 'sm'}
          className={cn({
            'text-grey-400': isBetaText,
            'text-text-primary': !isBetaText,
          })}
        >
          {feature.text}
        </Text>
      );
    }

    if (feature.isIncluded) {
      return <StyledIcon name="check" color="green.500" />;
    }

    return <StyledIcon name="close" color="grey.300" size="base" />;
  };

  return (
    <Wrapper {...props}>
      <TableWrapper>
        <StyledTable>
          <caption>
            <VisuallyHidden>Pricing tiers available for Fluent Cargo software</VisuallyHidden>
          </caption>
          <thead>
            <tr>
              <th>
                <VisuallyHidden>Spacer</VisuallyHidden>
              </th>
              <th scope="col">
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Basic
                </Heading>
                <Text variant="sm" className="leading-snug">
                  An introduction to
                  <br /> Fluent Cargo
                </Text>
                <Cost className="bg-yellow text-baseSm">Free to use</Cost>
              </th>
              <th scope="col">
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Starter
                </Heading>
                <Text variant="sm" className="leading-snug">
                  Starter account with
                  <br /> most features
                </Text>
                <Cost className="bg-yellow text-baseSm">Free with an account</Cost>
              </th>
              <th scope="col">
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Fluent
                </Heading>
                <Text variant="sm" className="leading-snug">
                  Pro business account
                  <br /> with all features
                </Text>
                <Cost className="bg-lightBlue-50 text-baseSm">Coming soon</Cost>
              </th>
              <th>
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Fluent +
                </Heading>
                <Text variant="sm" className="leading-snug">
                  Unlimited Pro business
                  <br /> account with all features
                </Text>
                <Cost className="bg-lightBlue-50 text-baseSm">Coming soon</Cost>
              </th>
            </tr>
          </thead>
          <tbody>
            {map(featureCategories, (category) => {
              return (
                <>
                  <tr key={category.id}>
                    <th>
                      <Heading variant="h6" as="h4" className="mt-base text-lightBlue-600">
                        {category.heading}
                      </Heading>
                    </th>
                    <td aria-label="empty">&nbsp;</td>
                    <td aria-label="empty">&nbsp;</td>
                    <td aria-label="empty">&nbsp;</td>
                    <td aria-label="empty">&nbsp;</td>
                  </tr>
                  {map(category.features, (feature) => {
                    return (
                      <tr key={feature.id}>
                        <th>{feature.heading}</th>
                        <td>{renderFeatureCell(feature.free[0])}</td>
                        <td>{renderFeatureCell(feature.fast[0])}</td>
                        <td>{renderFeatureCell(feature.fluent[0])}</td>
                        <td>{renderFeatureCell(feature.fluentPlus[0])}</td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th aria-label="empty">&nbsp;</th>
              <td aria-label="empty">&nbsp;</td>
              <td>
                <Button
                  href={`${SIGN_IN_PAGE_URL}?${CREATE_ACCOUNT_QUERY_STRING}=${SHOULD_CREATE_ACCOUNT}`}
                  variant="primaryLight"
                  className="mt-md"
                >
                  Sign up now for free!
                </Button>
              </td>
              <td>
                <Button variant="secondaryLight" className="mt-md" disabled>
                  Coming soon
                </Button>
              </td>
              <td>
                <Button variant="secondaryLight" className="mt-md" disabled>
                  Coming soon
                </Button>
              </td>
            </tr>
          </tfoot>
        </StyledTable>
      </TableWrapper>
      <Flex p="md" display={{ _: 'flex', md: 'none' }} alignItems="center">
        <Button
          href={`${SIGN_IN_PAGE_URL}?${CREATE_ACCOUNT_QUERY_STRING}=${SHOULD_CREATE_ACCOUNT}`}
          variant="primaryLight"
          className="w-full"
        >
          Sign up now for free!
        </Button>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  margin-bottom: ${themeGet('space.lg')};

  ${media.lg} {
    margin-bottom: ${themeGet('space.xxl')};
  }
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  max-width: var(--container-max-width);
  width: 100%;

  ${media.untilMd} {
    overflow-y: auto;
  }

  ${media.lg} {
    padding: 0 ${themeGet('space.lg')};
  }

  br {
    display: none;

    ${media.lg} {
      display: block;
    }
  }
`;

const StyledIcon = styled(Icon)``;

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  margin: 0;
  table-layout: auto;
  width: 100%;

  td,
  th {
  }

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: ${themeGet('zIndices.2')};
      background: ${themeGet('colors.grey.50')};
      padding: ${themeGet('space.md')};
      min-width: 164px;
      vertical-align: top;

      &:nth-child(3) {
        background-color: ${COLUMN_HIGHLIGHT_COLOR};

        &:before {
          content: 'Most popular';
          background-color: ${themeGet('colors.lightBlue.500')};
          color: ${themeGet('colors.white')};
          font-weight: ${themeGet('fontWeights.semiBold')};
          font-size: ${themeGet('fontSizes.baseSm')};
          text-align: center;
          padding: ${themeGet('space.sm')};
          line-height: ${themeGet('lineHeights.snug')};
          left: 0;
          right: 0;
          bottom: 100%;
          position: absolute;
        }
      }

      ${media.lg} {
        min-width: none;
      }

      /* Fake top background (left) */
      &:first-child {
        min-width: ${themeGet('space.14')};
        position: sticky;
        left: 0;

        ${media.md} {
          z-index: ${themeGet('zIndices.3')};
        }

        &:before {
          background-color: ${themeGet('colors.grey.50')};
          bottom: 0;
          display: none;
          position: absolute;
          right: 100%;
          top: 0;
          width: 5000px;
          content: '';

          ${media.md} {
            display: block;
          }
        }
      }

      /* Fake top background (right) */
      &:last-child {
        &:after {
          background-color: ${themeGet('colors.grey.50')};
          bottom: 0;
          content: '';
          display: none;
          left: 100%;
          position: absolute;
          right: auto;
          top: 0;
          width: 5000px;

          ${media.md} {
            display: block;
          }
        }
      }
    }
  }

  td {
    background: ${themeGet('colors.white')};
    text-align: center;
  }

  tbody {
    th,
    td {
      border-bottom: 1px solid ${themeGet('colors.grey.100')};
      padding: ${themeGet('space.sm')} 0;

      ${media.lg} {
        padding: ${themeGet('space.3')} 0;
      }
    }

    th {
      background: ${themeGet('colors.white')};
      font-weight: ${themeGet('fontWeights.normal')};
      left: 0;
      padding-left: ${themeGet('space.base')};
      position: sticky;
      text-align: left;
      z-index: ${themeGet('zIndices.2')};

      /* Column shadow on mobile */
      &:after {
        background: linear-gradient(90deg, ${themeGet('colors.grey.100')} 0%, rgba(255, 255, 255, 0) 100%);
        left: 100%;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: ${themeGet('space.sm')};

        ${media.md} {
          display: none;
        }
      }

      ${media.untilMd} {
        border-right: 1px solid ${themeGet('colors.grey.200')};
        font-size: ${themeGet('fontSizes.baseSm')};
        padding-right: ${themeGet('space.base')};
      }

      ${media.lg} {
        padding-left: 0;
      }
    }

    td {
      text-align: center;
      vertical-align: middle;

      // Give the 3rd column a blue background
      &:nth-child(3) {
        background: ${COLUMN_HIGHLIGHT_COLOR};
      }

      // Grey out the unreleased tiers
      &:nth-child(n + 4) {
        filter: grayscale(100%);
        opacity: 0.5;
      }

      ${StyledIcon} {
        display: flex;
        justify-content: center;
      }

      p {
        margin: 0;
      }
    }
  }

  tfoot {
    display: none;

    ${media.md} {
      display: table-row-group;
    }

    th {
      background: white;
      left: 0;
      position: sticky;
      z-index: ${themeGet('zIndices.2')};
    }

    th,
    td {
      padding-bottom: ${themeGet('space.lg')};

      &:nth-child(3) {
        background: ${COLUMN_HIGHLIGHT_COLOR};
      }
    }
  }
`;

const Cost = styled(Text)`
  border-radius: ${themeGet('radii.default')};
  display: inline-block;
  font-weight: ${themeGet('fontWeights.medium')};
  line-height: ${themeGet('lineHeights.none')};
  margin-bottom: 0;
  padding: ${themeGet('space.xs')} ${themeGet('space.sm')};
`;

export { PricingTiers };
