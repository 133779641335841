import { Box } from '@/components/box';
import { FeaturedIcon } from '@/components/featured-icon';
import { Heading } from '@/components/heading';
import { RichText } from '@/components/rich-text';
import { BlockBody, BlockHeading } from '@/types/block-types';

export interface TextCardProps {
  __typename?: string;
  icon?: string;
  id: string;
  heading?: BlockHeading;
  body?: BlockBody;
}

const TextCard = ({ icon, id, heading, body, ...props }: TextCardProps) => {
  return (
    <Box key={id} {...props}>
      {icon && <FeaturedIcon icon={icon} />}
      {heading && (
        <Heading variant="h4" as="h3" className="mb-base">
          {heading}
        </Heading>
      )}
      {body && <RichText>{body}</RichText>}
    </Box>
  );
};

export { TextCard };
