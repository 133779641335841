/* eslint-disable jsx-a11y/control-has-associated-label */

import { LoadingSpinner } from '@/components/ui/loading/spinner';
import { UserMessage } from '@/components/ui/user-message';
import { useSubscriptionPlans } from '@/hooks/use-subscription-plans';
import { tv } from '@/utils/styles';
import { getPlanPrice, getPlanPriceString } from '@/utils/subscriptions';
/**
 * PlanListingExtended
 *
 * Note: This listing is hardcoded until we figure out a nice way to sync
 * this with either Stripe or our own Database. DatoCMS is probably not
 * the right place for this anymore now that our source of truth is connected
 * to user Entitlements
 */

const PlanListingExtended = () => {
  const { isLoading, plans, error } = useSubscriptionPlans();

  if (error) return <UserMessage variant="error" heading={error} />;
  if (isLoading || !plans.length) return <LoadingSpinner isVisible />;

  const [starterPlan, basicPlan, teamPlan, proPlan] = plans;

  // Plan Prices
  const teamPlanPrice = getPlanPrice(teamPlan);
  const basicPlanPrice = getPlanPrice(basicPlan);
  const proPlanPrice = getPlanPrice(proPlan);

  const { overflow, table, tr, th, td, category } = styles();

  return (
    <div className={overflow()}>
      <table className={table()}>
        <thead>
          <tr className={tr()}>
            <th className={th()}>&nbsp;</th>
            <th className={th()}>{starterPlan.name}</th>
            <th className={th()}>{basicPlan.name}</th>
            <th className={th()}>{teamPlan.name}</th>
            <th className={th()}>{proPlan.name}</th>
          </tr>
        </thead>
        <tbody>
          {teamPlanPrice && proPlanPrice && basicPlanPrice ? (
            <tr className={tr()}>
              <td className={td()}>Cost per month</td>
              <td className={td()}>Free</td>
              <td className={td()}>
                {getPlanPriceString(basicPlanPrice)} / {basicPlanPrice.interval}
              </td>
              <td className={td()}>
                {getPlanPriceString(teamPlanPrice)} / {teamPlanPrice.interval}
              </td>
              <td className={td()}>
                {getPlanPriceString(proPlanPrice)} / {proPlanPrice.interval}
              </td>
            </tr>
          ) : null}
          <tr className={tr()}>
            <td className={td()}>
              <span className={category()}>Usage</span>
            </td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
          <tr className={tr()}>
            <td className={td()}>User accounts</td>
            <td className={td()}>1</td>
            <td className={td()}>1</td>
            <td className={td()}>Up to 3</td>
            <td className={td()}>Up to 8</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Route searches</td>
            <td className={td()}>10 / month</td>
            <td className={td()}>30 / month</td>
            <td className={td()}>300 / month</td>
            <td className={td()}>1200 / month</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Schedule searches</td>
            <td className={td()}>5 / month</td>
            <td className={td()}>15 / month</td>
            <td className={td()}>150 / month</td>
            <td className={td()}>600 / month</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Shipments tracked</td>
            <td className={td()}>None</td>
            <td className={td()}>1 / month</td>
            <td className={td()}>5 / month</td>
            <td className={td()}>25 / month</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>
              <span className={category()}>Routing & Schedules</span>
            </td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
          <tr className={tr()}>
            <td className={td()}>A to B route search</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Routes paths on interactive map</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Detailed route itineraries</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Air schedules</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Ocean schedules</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>CO2 emission estimates</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Advanced routing search</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Port and Canal Avoidance</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Search History</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>
              <span className={category()}>Shipment Tracking</span>
            </td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
          <tr className={tr()}>
            <td className={td()}>Shipping container tracking</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Air Cargo tracking</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Track using MBOL</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Live map updates & AIS tracking</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Realtime ETA updates</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Filters and saved views</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Timeline View</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Change logs</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Bulk Upload</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>
              <small>Coming soon</small>
            </td>
            <td className={td()}>
              <small>Coming soon</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>
              Shipment top-ups{' '}
              <small className="block text-text-secondary">Purchase additional tracking capacity</small>
            </td>
            <td className={td()} />
            <td className={td()}>
              <small>Coming soon</small>
            </td>
            <td className={td()}>
              <small>Coming soon</small>
            </td>
            <td className={td()}>
              <small>Coming soon</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Email notifications</td>
            <td className={td()} />
            <td className={td()}>
              <small>Coming soon</small>
            </td>
            <td className={td()}>
              <small>Coming soon</small>
            </td>
            <td className={td()}>
              <small>Coming soon</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>High volume shipment tracking</td>
            <td className={td()} />
            <td className={td()}>
              <small>Contact us</small>
            </td>
            <td className={td()}>
              <small>Contact us</small>
            </td>
            <td className={td()}>
              <small>Contact us</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>
              <span className={category()}>Research tools & Content</span>
            </td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
          <tr className={tr()}>
            <td className={td()}>Airport and Seaport data</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Carrier/Operator information</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Port Insights</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Congestion & delay data</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>
              <span className={category()}>API Access</span>
            </td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
          <tr className={tr()}>
            <td className={td()}>Routing and Schedules API</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>
              <small>Contact us</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Shipment Tracking API</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>
              <small>Contact us</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Port and Carriers API</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>
              <small>Contact us</small>
            </td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>
              <span className={category()}>Support</span>
            </td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
          <tr className={tr()}>
            <td className={td()}>General Support</td>
            <td className={td()} />
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()}>Priority Support</td>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()}>✓</td>
          </tr>
          <tr className={tr()}>
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
            <td className={td()} />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const styles = tv({
  slots: {
    overflow: '-mx-4 my-0 overflow-auto pl-4 md:mx-none md:my-none md:pl-none',
    table: 'w-full min-w-[650px] border-collapse',
    tr: '[&:last-child>td:first-child]:border-b-0 [&:last-child_td]:px-3 [&:last-child_td]:py-4 [&>:first-child]:w-[34%] [&>:first-child]:border-t-0 [&>:first-child]:bg-transparent [&>:first-child]:text-left',
    th: 'border-b border-r border-t border-grey-200 bg-white py-3 text-center text-md font-medium',
    td: 'w-[16%] border-b border-r border-grey-200 bg-white py-2 text-center text-base',
    category: 'block pt-4 text-sm font-medium uppercase text-lightBlue-600',
  },
});

export { PlanListingExtended };
