import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { TextInput } from '@/components/inputs/text-input';
import { SubscribeFormInputs } from '@/types';
import { tv } from '@/utils/styles';

export interface SubscribeFormProps {
  successHeading: string;
  successBody: string;
  onSubmit: (data: SubscribeFormInputs) => void;
  className?: string;
}

const SubscribeForm = ({ successHeading, successBody, onSubmit, className, ...props }: SubscribeFormProps) => {
  const { base, button, form, formFields, formFieldsInner, successHeadingStyles, successWrapper } = styles();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useFormContext<SubscribeFormInputs>();

  return (
    <div className={base({ className })} {...props}>
      {!isSubmitSuccessful ? (
        <form onSubmit={handleSubmit(onSubmit)} className={form()}>
          <div className={formFields()}>
            <div className={formFieldsInner()}>
              <TextInput
                label="Email address"
                errors={errors}
                placeholder="Your email address"
                hideLabel
                showShadow={false}
                {...register('email', { required: true })}
              />

              <div>
                <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting} className={button()}>
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className={successWrapper()}>
          <Heading variant="h2" as="h2" className={successHeadingStyles()}>
            {successHeading}
          </Heading>
          <Heading variant="h4" as="p" className="mb-0">
            {successBody}
          </Heading>
        </div>
      )}
    </div>
  );
};

const styles = tv({
  slots: {
    base: '',
    block: 'relative',
    button: 'mt-3 w-full sm:ml-3 sm:mt-0 sm:w-auto',
    form: 'mx-auto max-w-lg',
    formFields: 'flex w-full items-center',
    formFieldsInner: 'block w-full sm:flex',
    successHeadingStyles: 'mb-2',
    successWrapper: 'flex flex-col text-center',
  },
});

export { SubscribeForm };
