import { Globe } from 'lucide-react';

import { Block } from '@/components/block';
import { Image } from '@/components/image';
import { TestimonialBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const TestimonialBlock = ({ id, testimonial }: TestimonialBlockProps) => {
  const { quote, author, jobTitle, companyName, url, image } = testimonial;

  const {
    base,
    inner,
    quoteStyles,
    imageStyles,
    icon,
    authorInfo,
    company,
    authorName,
    jobTitleStyles,
    companyNameStyles,
  } = styles();

  return (
    <Block id={id} className={base()}>
      <blockquote className={inner()}>
        {image?.responsiveImage && <Image data={image} className={imageStyles()} />}

        <div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={quoteStyles()} dangerouslySetInnerHTML={{ __html: quote }} />
          <footer className={authorInfo()}>
            <p className={authorName()}>{author}</p>

            {jobTitle && <p className={jobTitleStyles()}>{jobTitle}</p>}

            {url ? (
              <a href={url} className={company()} target="_blank" rel="noopener noreferrer">
                <Globe className={icon()} /> <span className={companyNameStyles()}>{companyName}</span>
              </a>
            ) : (
              <p className={company()}>{companyName}</p>
            )}
          </footer>
        </div>
      </blockquote>
    </Block>
  );
};

const styles = tv({
  slots: {
    base: 'max-md:py-0',
    inner:
      'grid gap-x-12 bg-brandSoftBlue p-6 text-md text-brandDarkBlue max-md:-mx-5 max-md:gap-y-8 sm:p-12 md:grid-cols-[200px_1fr] md:rounded-lg lg:grid-cols-[280px_1fr] lg:gap-x-20 lg:p-16',
    quoteStyles: 'blockquote text-md leading-8 [&_p]:inline',
    imageStyles: 'mx-auto rounded-full object-cover',
    authorName: 'mb-0 font-semibold not-italic',
    authorInfo: 'mt-6',
    company: 'flex items-center gap-x-2',
    companyNameStyles: 'text-lightBlue-500 underline underline-offset-4 hover:no-underline',
    jobTitleStyles: '-mt-1 mb-0',
    icon: 'inline-block text-lightBlue-500',
  },
});

export { TestimonialBlock };
