import { Block } from '@/components/block';
import { Box } from '@/components/box';
import { ButtonGroup } from '@/components/button-group';
import { Flex } from '@/components/flex';
import { Heading } from '@/components/heading';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { DynamicIcon } from '@/components/ui/dynamic-icon';
import { ProductFeaturesBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ProductFeaturesBlock = ({
  blockAlignment,
  body,
  children,
  id,
  heading,
  variant: blockVariant,
  blockLayout = 'horizontal',
  columns = 'two',
  buttons,
  noTopPadding = false,
  items,
}: ProductFeaturesBlockProps) => {
  const { base, layoutGrid, grid, iconWrapper } = styles({
    variant: blockVariant,
    layoutVariant: blockLayout,
    contentColumnVariant: columns,
  });

  return (
    <div className={base()}>
      <Block id={id} headingVariant="h2" blockAlignment={blockAlignment} noTopPadding={noTopPadding}>
        <div className={layoutGrid()}>
          {(heading || body) && (
            <Box textAlign={blockAlignment}>
              {heading && (
                <Heading variant="h2" as="h2" className="mb-md">
                  {heading}
                </Heading>
              )}
              {body && <RichText>{body}</RichText>}
              {buttons && <ButtonGroup buttons={buttons} className="mt-lg justify-start" />}
            </Box>
          )}
          <div className={grid()}>
            {children ||
              items?.map((item) => {
                const { id: itemId, title, description, iconName } = item;
                return (
                  <Flex key={itemId} mb="lg">
                    <div className={iconWrapper()}>
                      <DynamicIcon name={iconName} size={24} className="text-lightBlue-500" />
                    </div>
                    <Box flex="1">
                      <Heading variant="h4" as="h3">
                        {title}
                      </Heading>
                      <Text variant="secondary" className="mb-0 mt-2 text-grey-600">
                        {description}
                      </Text>
                    </Box>
                  </Flex>
                );
              })}
          </div>
        </div>
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'text-text-primary [&_h2]:mb-2 [&_ul]:mt-8',
    layoutGrid: 'grid gap-12',
    grid: 'grid gap-x-12 gap-y-6',
    iconWrapper: 'mr-5 grid h-10 w-10 place-items-center rounded-md bg-blue-50',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      dark: {},
      grey: {
        base: 'bg-grey-50',
      },
    },
    layoutVariant: {
      vertical: {},
      horizontal: {
        layoutGrid: 'grid-cols-1 last:pt-2 lg:grid-cols-[1fr_2fr]',
      },
    },
    contentColumnVariant: {
      two: {
        grid: 'grid-cols-1 md:grid-cols-2',
      },
      three: {
        grid: 'grid-cols-1 md:grid-cols-3',
      },
      four: {
        grid: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4',
      },
    },
  },
});

export { ProductFeaturesBlock };
