import { Block } from '@/components/block';
import { Link } from '@/components/link';
import { PlanListing } from '@/components/modules/plan/listing';
import { PlanListingExtended } from '@/components/modules/plan/listing-extended';
import { Text } from '@/components/text';
import { PlansBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const PlansBlock = ({
  body,
  id,
  heading,
  blockAlignment = 'center',
  variant = 'grey',
  showInclusions = false,
  noTopPadding = false,
  showFullTable = false,
}: PlansBlockProps) => {
  const { base, linkWrapper, linkContent, extendedContent, text } = styles({ variant });

  return (
    <div className={base()} id="plans">
      <Block
        id={id}
        heading={heading}
        blockAlignment={blockAlignment}
        headingVariant="h1"
        headingElement="h2"
        body={body}
        className="!pb-48"
        noTopPadding={noTopPadding}
      />
      <div className={linkWrapper()}>
        <div className={linkContent()}>
          <PlanListing showInclusions={showInclusions} />
          {!showFullTable && (
            <Text variant="sm" className={text()}>
              <Link href="/plans#full-list">See full list of features &amp; inclusions</Link>
            </Text>
          )}
        </div>
      </div>
      {showFullTable && (
        <Block
          id="full-list"
          blockAlignment={blockAlignment}
          headingVariant="h1"
          headingElement="h2"
          heading="Full list of features & inclusions"
          body="See the full list of features and inclusions that each plan offers."
        >
          <div className={extendedContent()}>
            <PlanListingExtended />
          </div>
        </Block>
      )}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'text-text-primary',
    text: 'mb-0 mt-6 text-center',
    linkWrapper: 'bg-white py-3xl',
    linkContent: 'mx-auto -mt-64 max-w-[var(--center-content-max-width)]',
    extendedContent: 'mx-auto max-w-[var(--center-content-max-width)]',
  },
  variants: {
    variant: {
      light: { base: 'bg-white' },
      grey: { base: 'bg-grey-50' },
      dark: {},
    },
  },
});

export { PlansBlock };
