import mapTheme from '@/components/map/theme';

const defaultMapOptions = {
  center: {
    lat: 20,
    lng: 20,
  },
  zoom: 2.8,
  options: {
    disableDefaultUI: true,
    maxZoom: 10,
    minZoom: 1,
    styles: mapTheme,
  },
};

const zoomToOptions = {
  selectedPort: {
    zoom: 7,
    panBy: {
      x: -280,
      y: 0,
    },
  },
};

export { defaultMapOptions, zoomToOptions };
