/**
 * Validates a container ID.
 *
 * A container ID typically follows this format:
 * - The first three characters represent the owner code (carrier ID).
 * - The fourth character is a category identifier, which can be:
 *   - 'J' for detachable freight container related equipment
 *   - 'R' for reefer (refrigerated) containers
 *   - 'U' for freight containers
 *   - 'Z' for trailers and chassis
 * - The remaining characters represent the serial number, which can be 6 to 10 digits long (typically 7).
 *
 * Example: MAEU9623307
 * Breakdown: {MAE = Owner Code}{U = Category}{9623307 = Serial Number}
 */
export const validateContainerId = (containerId: string | undefined): boolean => {
  if (!containerId) return false;
  // This regex matches a container ID with the following format:
  // - The first three characters are any letters (owner code)
  // - The fourth character is one of 'J', 'R', 'U', or 'Z' (category identifier)
  // - The remaining characters are 6 to 10 digits (serial number)
  const regex = /^[A-Za-z]{3}[JRUZ][0-9]{6,10}$/;
  return regex.test(containerId);
};
